import ebn_character_configs from "@/store/datas/ebn_character_configs";
import { EbnCharacter } from "@/store/models/EbnCharacters";
import ebn_state from "@/store/modules/ebn_state";
import IBLManager from "@/webgl/gl/IBLManager";
import Scene from "@/webgl/Scene";
import { Uniform } from "nanogl-pbr/Input";
import CharacterController from "./CharacterController";


export class Characters {
  

  controllers : Map<EbnCharacter, CharacterController> = new Map()

  _current : CharacterController
  private _running: boolean;
  iblMngr: IBLManager;

  private _loadPromise: Promise<void>;

  private watchCurrent:any;
  private watchDisclamer:any;
  

  init( scene : Scene ){
    this.iblMngr     = new IBLManager( scene )

    for (const cfg of ebn_character_configs) {
      const ctrl = new CharacterController(cfg, scene)
      this.controllers.set( cfg.id, ctrl )
    }


    this.watchCurrent = ebn_state.$watch( s=>s.currentCharacter, (newval)=>this.setActiveCharacter(newval))
    this.setActiveCharacter(ebn_state.currentCharacter)
    
    this.watchDisclamer = ebn_state.$watch( s=>s.disclaimerOpened, ()=>this.disclaimerChange())
  }
  
  start(){
    this._running = true
    this.setActiveCharacter(ebn_state.currentCharacter)
  }
  
  stop(){
    this._running = false
    this._current?.stop()
    this._current = null
  }

  dispose() {
    this.stop()
    this._running = false
    this.iblMngr?.dispose()
    this.watchCurrent()
    this.watchDisclamer()
    Array.from(this.controllers.values()).forEach( ctrl=>ctrl.dispose() )
    this.controllers.clear()
  } 
  
  setActiveCharacter( charid : EbnCharacter ){
    if( ! this._running ) return
    const ctrl = this.controllers.get(charid)
    this._current?.stop()
    this._current = ctrl
    this._current.start()
  }

  disclaimerChange(): void {
    if( !ebn_state.disclaimerOpened ){
      this.markDisclaimerClosed()
    }
  }
  
  skipTuto(){
    this._current?.exitTuto()
  }

  markAsSuccess( uspIndex? : number){
    this._current?.markAsSuccess(uspIndex)
  }

  markAsFailure( ){
    this._current?.markAsFailure()
  }

  markDisclaimerClosed( ){
    this._current?.markDisclaimerClosed()
  }


  update() {
    this._current?.preRender()
  }

  preRender() {
    0
  }
  
  rttRender() {
    this._current?.rttRender()
  }
  
  render() {
    this._current?.render()
  }
  

  lazyLoad(): Promise<void>{
    // if( !this._loadPromise){
      this._loadPromise = this._loadCommon()
    // }
    return this._loadPromise
  }

  async _loadCommon(): Promise<void>{
    await this.iblMngr.loadDefault();
    (this.iblMngr.exposure.param as Uniform)._value[0] = 3
  }


}

const ebnCharacterControllers = new Characters()

export default ebnCharacterControllers