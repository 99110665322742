
















import Vue from 'vue'
import Component from 'vue-class-component';
import { ModelSync, Prop } from 'vue-property-decorator';
import UIIconBtn from '../UIIconBtn/UIIconBtn.vue';

@Component({
  components:{
    UIIconBtn
  }
})
export default class EBNToggleBtn extends Vue {

  @ModelSync('checked', 'change', { type: Boolean }) readonly checkedValue!: boolean

  @Prop({type:String, required:true}) icon!: string

}

