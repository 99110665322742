














import { EbnCharacters } from "@/store/models/EbnCharacters";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
// import { Prop } from "vue-property-decorator";

@Component
export default class EBNCarouselProgress extends Vue {
  @Prop({ type: Number, required: true }) selected!: number;

  get characters() {
    return EbnCharacters;
  }

  mounted() {
    this.$el.classList.add("show");
  }
}
