













import gsap, { Sine } from "gsap";
import { Expo } from "gsap/all";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CustomEase from "../EBNIntro/CustomEase";
import ChevronLeft from "!vue-svg-loader!@/assets/icons/chevron-left.svg";

const easeBack = CustomEase.create(
  "custom",
  "M0,0 C0.128,0.572 0.229,1.081 0.49,1.03 0.642,1 0.838,1 1,1 "
);

@Component({
  components: {
    ChevronLeft,
  },
})

export default class EBNSubtitleBubble extends Vue {
  @Prop({ type: String, required: false }) text: string;

  timeline: gsap.core.Timeline;
  hideTw: gsap.core.Tween;
  oldText: string = "";

  get currentText(): string {
    return this.text !== null ? this.text : this.oldText;
  }

  @Watch("text")
  onTextChange(text: string) {
    if (text !== null) {
      this.$nextTick(() => {
        this.buildTimeLine();
        this.timeline.invalidate().restart();
      });
      this.oldText = text;
    } else this.hide();
  }

  hide() {
    const wrapper = this.$el;
    this.hideTw = gsap.fromTo(
      wrapper,
      { opacity: 1 },
      { duration: 0.15, ease: Sine.easeOut, opacity: 0 }
    );
  }

  buildTimeLine() {
    if (this.hideTw) this.hideTw.kill();
    const wrapper = this.$el;
    const arrow = this.$el.querySelector(".arrow");
    const text = this.$el.querySelector("span");
    this.timeline = gsap.timeline({ paused: true });
    gsap.set(wrapper, { opacity: 1 });
    this.timeline
      .fromTo(
        wrapper,
        {
          scale: 0,
          rotation: 25,
        },
        {
          duration: 0.5,
          scale: 1,
          rotation: 0,
          ease: easeBack,
          clearProps: 'transform'
        }
      )
      .fromTo(
        arrow,
        {
          x: 54,
          y: 0,
          rotation: 4,
        },
        {
          duration: 0.5,
          delay: 0.1,
          rotation: 0,
          x: 44,
          y: -22,
          ease: Expo.easeOut
        },
        0
      )
      .fromTo(
        text,
        {
          scale: 0,
        },
        {
          duration: 0.6,
          scale: 1,
          ease: easeBack,
          clearProps: 'transform'
        },
        0
      );
  }
}
