











import Vue from "vue";
import Component from "vue-class-component";
import EBNBurgerMenu from "../EBNBurgerMenu/EBNBurgerMenu.vue";
import EBNCharacterSelector from "../EBNCharacterSelector/EBNCharacterSelector.vue";
import EBNCarFeatures from '../EBNCarFeatures/EBNCarFeatures.vue';
import { Prop } from "vue-property-decorator";
import ebn_state from '@/store/modules/ebn_state';

@Component({
  components: {
    EBNCharacterSelector,
    EBNCarFeatures,
    EBNBurgerMenu,
  },
})
export default class EBNNavBar extends Vue {
  @Prop({ type: String, required: false }) text: string;
}
