



















import Delay from "@/core/Delay";
import Stagger from "@/core/Stagger";
import TrackingEbn from "@/core/TrackingEbn";
import SchemeColoredMixin from "@/mixins/SchemeColored";
import { EbnCharacter } from "@/store/models/EbnCharacters";
import ebn_state, { EBNMainState } from "@/store/modules/ebn_state";
import { remapProgress } from "@/webgl/math";
import { easeInQuad } from "@/webgl/math/ease";
import gsap, { Back, Sine } from "gsap";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import EBNButton from "../EBNButton/EBNButton.vue";
import CustomEase from "../EBNIntro/CustomEase";
import ebn_market from '../../store/modules/ebn_market';

const easeBack = CustomEase.create(
  "custom",
  "M0,0 C0.128,0.572 0.229,1.081 0.49,1.03 0.642,1 0.838,1 1,1 "
);

const CHAR_UI_TIMELINESCALE = 0.5;

@Component({
  components: {
    EBNButton,
  },

  mixins: [SchemeColoredMixin],
})
export default class CharacterUI extends Vue {
  @Prop({ type: String, required: true }) characterId!: EbnCharacter;

  @Prop({ type: Number, required: true }) position!: number;
  @Prop({ type: Number, required: true }) id!: number;

  timeline: any;

  showUI: boolean = false;
  started: boolean = false;
  selected: boolean = false;

  onSelect() {
    // if (this.characterId === "joel") return;
    TrackingEbn.meetBtn(this.characterId);
    ebn_state.setCurrentCharacter(this.characterId);
    ebn_state.setMainState(EBNMainState.AR);
    this.$emit("onSelectChar");
  }

  get visible() {
    return !this.started || Math.abs(this.position) < 1.1;
  }

  get current() {
    return this.position > -0.5 && this.position <= 0.5;
  }

  get charname(): string {
    return this.$i18n
      .t(`${this.characterId}.name`)
      .toString()
      .toUpperCase()
      .split("")
      .map((c) => `<span>${c}</span>`)
      .join("");
  }

  get gender(): string {
    return this.$i18n.t(`${this.characterId}.gender`).toString();
  }

  get baseline(): string {
    return this.$i18n.t(`${this.characterId}.baseline`).toString();
  }

  get mx(): boolean {
    return ebn_market.currentMarketEBN == "mx";
  }

  get ctaText(): string {
    return `${this.$i18n.t(`carousel.cta`)} ${this.characterId.toUpperCase()}`;
  }

  get clippathStyle() {
    if (this.current) return "";
    return `clip-path: url(#path-${this.characterId});`;
  }

  get progressVar() {
    return `--ebn-char-progress: ${this.position}`;
  }

  getRevisit() {
    return ebn_state.isRevisit > 1
  }

  get stagger(): number[] {
    let p = Math.abs(this.position);
    p = remapProgress(p, 0.0, 0.5);
    const sign = this.position > 0 ? 1 : -1;
    return Stagger(p, 4, 0.05).map((n) => sign * easeInQuad(n));
  }

  get t0() {
    return this.stagger[0] * 200;
  }
  get t1() {
    return this.stagger[1] * 200;
  }
  get t2() {
    return this.stagger[2] * 200;
  }
  get t3() {
    return this.stagger[3] * 200;
  }

  get o0() {
    return 1.0 - Math.abs(this.stagger[0]);
  }
  get o1() {
    return 1.0 - Math.abs(this.stagger[1]);
  }
  get o2() {
    return 1.0 - Math.abs(this.stagger[2]);
  }
  get o3() {
    return 1.0 - Math.abs(this.stagger[3]);
  }

  buildTimeline() {
    const gender = this.$el.querySelector(".gender");
    const charname = this.$el.querySelectorAll(".charname > span");
    const baseline = this.$el.querySelectorAll(".baseline");
    const btn: HTMLElement = this.$el.querySelector(".btn") as HTMLElement;

    const btnWidth = btn.getBoundingClientRect().width;

    gsap.set(btn.querySelector("div"), { width: btnWidth });
    this.timeline = gsap.timeline({ paused: true });
    this.timeline
      .fromTo(
        charname,
        { rotation: 0, y: 30 },
        {
          duration: 0.01,
          rotation: "random(-30, 30)",
          y: 30,
        }
      )
      .to(
        charname,
        {
          duration: 1 * CHAR_UI_TIMELINESCALE,
          rotation: 0,
          y: 0,
          stagger: 0.03 * CHAR_UI_TIMELINESCALE,
          ease: Back.easeOut,
          delay: 0.1 * CHAR_UI_TIMELINESCALE,
        },
        "<"
      )
      .fromTo(
        charname,
        { opacity: 0 },
        {
          duration: 0.1 * CHAR_UI_TIMELINESCALE,
          opacity: 1,
          stagger: 0.03,
          ease: Sine.easeOut,
          delay: 0.1 * CHAR_UI_TIMELINESCALE,
        },
        "<"
      )
      .fromTo(
        gender,
        { rotation: 15, y: 30 },
        {
          duration: 1 * CHAR_UI_TIMELINESCALE,
          rotation: 0,
          delay: 0.1 * CHAR_UI_TIMELINESCALE,
          y: 0,
          ease: easeBack,
        },
        "<"
      )
      .fromTo(
        gender,
        { opacity: 0 },
        {
          duration: 0.2 * CHAR_UI_TIMELINESCALE,
          opacity: 1,
          ease: Sine.easeOut,
          delay: 0.1 * CHAR_UI_TIMELINESCALE,
        },
        "<"
      )
      .fromTo(
        baseline,
        { opacity: 0, y: 30 },
        {
          duration: 0.5 * CHAR_UI_TIMELINESCALE,
          opacity: 1,
          y: 0,
          ease: Sine.easeOut,
          delay: 0.3 * CHAR_UI_TIMELINESCALE,
        },
        "<"
      )
      .fromTo(
        btn,
        {
          opacity: 0,
          y: 30,
          x: "-50%",
        },
        {
          duration: 0.5 * CHAR_UI_TIMELINESCALE,
          opacity: 1,
          y: 0,
          x: "-50%",
          ease: Sine.easeOut,
          delay: 0.4 * CHAR_UI_TIMELINESCALE,
        },
        "<"
      )
      .fromTo(
        btn,
        {
          width: 10,
          height: 10,
          rotation: 30,
        },
        {
          duration: 0.5 * CHAR_UI_TIMELINESCALE,
          width: btnWidth,
          rotation: 0,
          height: 50,
          ease: Sine.easeOut,
          delay: 0.5 * CHAR_UI_TIMELINESCALE,
        },
        "<"
      )
      .fromTo(
        btn.querySelector("div"),
        {
          opacity: 0,
        },
        {
          duration: 0.5 * CHAR_UI_TIMELINESCALE,
          opacity: 1,
          ease: Sine.easeOut,
          delay: 0.7 * CHAR_UI_TIMELINESCALE,
        },
        "<"
      );
  }

  async mounted() {
    this.buildTimeline();
    this.started = true;
    await Delay(10);
    let oldP = 0;
    this.$watch("position", async (position) => {
      const p = remapProgress(1 - Math.abs(position), 0.65, 1);
      if (position > -0.25 && position < 0.25) {
        if (!this.selected) {
          TrackingEbn.swipe();
        }
        this.selected = true;
      } else {
        this.selected = false;
      }
      // if (this.characterId === "zoe") console.log(p * this.timeline.duration());
      // this.timeline.seek(p * this.timeline.duration());
      if (p > 0 && oldP < p && !this.showUI) {
        await Delay(250);
        this.timeline.timeScale(1);
        this.timeline.play();
        this.showUI = true;
      } else if (p < 1 && oldP > p && this.showUI) {
        this.timeline.timeScale(2);
        this.timeline.play().reverse();
        this.showUI = false;
      }

      oldP = p;
    });

    if (this.id === ebn_state.isRevisit) {
      await Delay(1000);
      this.timeline.timeScale(1);
      this.timeline.play();
      this.showUI = true;
    }
  }
}
