

















import ebnCharacterControllers from '@/webgl/entities/ebn/Characters';
import Vue from 'vue'
import Component from 'vue-class-component';
import EBNButton from '../EBNButton/EBNButton.vue';


@Component({
  components:{
    EBNButton,
  }
})
export default class GenericInteractionView extends Vue {


  onSuccess(){
    ebnCharacterControllers.markAsSuccess()
  }

  onFailure(){
    ebnCharacterControllers.markAsFailure()
  }

}

