/* eslint-disable @typescript-eslint/no-explicit-any */
import TrackingEbn from "@/core/TrackingEbn";
import { EbnAnimationIdentifier, EbnAnimationMultiSequence, EbnAnimationSequence, EbnAnimationSequence_getAnimationAt, isMultiAnim } from "@/store/models/EbnAnimationSequence";
import { CharacterConfig } from "@/store/models/EbnCharacters";
import ebn_market from "@/store/modules/ebn_market";
import ebn_state from "@/store/modules/ebn_state";
import EbnArStateMachine, { ArState, ArStateContext, ArStateEvent, ArStateValue } from "@/store/xstate/ebn_arstate";
import ARController from "@/webgl/ar/ARController";
import Scene from "@/webgl/Scene";
import { interpret, Interpreter, SingleOrArray, State, TransitionConfig, DoneInvokeEvent, InvokeConfig } from 'xstate';
import CharacterAudioLib from "./CharacterAudioLib";
import { CharacterInteractionFactory, ICharacterInteraction } from "./Characterinteraction";
import CharacterModel from "./CharacterModel";

function invokeAnimation(anim: EbnAnimationSequence, onDone? : string | SingleOrArray<TransitionConfig<ArStateContext, DoneInvokeEvent<any>>> ): InvokeConfig<ArStateContext, ArStateEvent> {
  return {
    src: {
      type: 'playAnimation',
      anim
    },
    onDone
  }
}


export default class CharacterController {


  
  audioLib: CharacterAudioLib;
  stateService: Interpreter<ArStateContext, any, ArStateEvent, ArState>;
  model: CharacterModel;
  interaction: ICharacterInteraction
  arControler : ARController

  

  
  _loadPromise : Promise<void> = null
  _active: boolean;
  
  _animCounters = new Map<EbnAnimationMultiSequence, number>([
    [EbnAnimationMultiSequence.FAILURE, 0],
    [EbnAnimationMultiSequence.SUCCESS, 0],
    [EbnAnimationMultiSequence.TRIGGER, 0],
    [EbnAnimationMultiSequence.WAITING, 0],
    [EbnAnimationMultiSequence.SPEECH, 0],
  ])

  _animSizes: Map<EbnAnimationMultiSequence, number>
  savedState: any;
  
  
  constructor( private config: CharacterConfig, readonly scene: Scene ){
    
    this._animSizes = new Map<EbnAnimationMultiSequence, number>([
      [EbnAnimationMultiSequence.FAILURE, config.numFailure],
      [EbnAnimationMultiSequence.SUCCESS, config.numSuccess],
      [EbnAnimationMultiSequence.TRIGGER, config.numTriggers],
      [EbnAnimationMultiSequence.WAITING, config.numWaitings],
    ])


    this.audioLib = new CharacterAudioLib( config )
    this.model = new CharacterModel( config.id, scene )
    this.interaction = CharacterInteractionFactory( config.id )
    this.interaction.init( this )
    this.arControler = new ARController(scene, this.model.root, false )

  }



  createStateMachine(){

    if(ebn_state.currentCharacter === "constance") {
      EbnArStateMachine.config.states.tuto.invoke = invokeAnimation( EbnAnimationIdentifier.IDLE );
    }

    // if(ebn_state.currentCharacter === "zoe") {
    //   EbnArStateMachine.config.states.waiting.states.loop.after[100] = 'motivation';
    // }

    if(ebn_state.currentCharacter === "zoe" || ebn_state.currentCharacter === "robert") {
      EbnArStateMachine.config.states.waiting.states.loop.invoke = invokeAnimation( EbnAnimationMultiSequence.WAITING );
      // EbnArStateMachine.config.states.waiting.states.motivation.invoke = invokeAnimation( EbnAnimationMultiSequence.MOTIVATION, 'loop' );
    }

    const stateMachine = EbnArStateMachine.withContext({
      ...EbnArStateMachine.context,
      usps: new Array(this.config.numUsps).fill(false),
      hasFailureAnim: true,
      animationController: this
    })

    this.stateService = interpret(stateMachine)
    .onTransition((state) => {
      this.onStateChange( state )
    })
    // .onEvent( (e)=>{
    //   console.log(e);
    // })

    if( this.savedState ){
      const stateDef = State.create(this.savedState )
      const stateInst = stateMachine.resolveState(stateDef as any)
      this.stateService.start( stateInst )
      this.savedState = null
    } else {
      this.stateService.start()
      this.stateService.send( 'LOAD' )
    }
  }



  destroyStateMachine(skipSave:boolean = false){
    if( !this.stateService ) return
    
    if( !skipSave || this.stateService.state.value !== 'end' ){
      this.savedState = this.stateService.state
    }

    if(skipSave) this.savedState = null

    this.stateService.send('DISPOSE')
    this.stateService.stop()
    this.stateService = null
  }



  get readyToRender(){
    return (
      this.stateService.state.context.loaded &&
      this.arControler.isPlaced
    )
  }
  
  
  public start(){
    if( this._active ) return
    this._active = true
    this.createStateMachine()
    this.onStateChange( this.stateService.state )
  }

  
  public stop(skipSave:boolean = true) {
    if( !this._active ) return
    
    this.interaction.leave()
    ebn_state.setCurrentSubtitles( null )
    this.audioLib.stopCurrent()
    this._active = false
    this.destroyStateMachine(skipSave)
    // if(!audio_state.isMuted) Howler.volume(1)
  }

  public dispose() {
    this.stop(true)
    
    this.audioLib?.dispose()
    this.model?.dispose()
    this.arControler?.stop()

    this.audioLib = null
    this.model = null
    this.arControler = null
    
  }

  preRender() {
    
    const audioSeq = this.audioLib._currentSequence
    
    if( audioSeq ){
      ebn_state.setCurrentSubtitles( audioSeq.currentSubtitles )
      ebn_state.setAnimationCurrentTime( audioSeq.currentTime )
    } else ebn_state.setCurrentSubtitles(null)
    
    this.arControler.update()
    this.model.preRender()
    
    if (this.stateService.state.value === 'tap_to_place'){
      if( this.arControler.isPlaced ){
        this.markAsPlaced()
      }
    }

    
    this.interaction.preRender()
  }



  

  public markAsPlaced(){
    this.stateService.send( 'PLACED' )
  }

  public markAsSuccess( uspIndex? : number){
    this.stateService.send( {type:'ACTION_SUCCESS', usp: uspIndex} )
  }

  public markAsFailure(){
    this.stateService.send( 'ACTION_FAILURE' )
  }
  
  public exitTuto() {
    this.stateService.send('EXIT_TUTO')
  }
  
  public markDisclaimerClosed() {
    this.stateService.send('CLOSE_DISCLAIMER')
  }


  playAnimation(animId: EbnAnimationSequence): Promise<void> {
    const finalId = this.resolveAnimation( animId )
    
    this.model.animator.playAnimation( this.audioLib.getSequence(finalId) )
    return this.audioLib.play( finalId )
  }
  
  
  resolveAnimation(animId: EbnAnimationSequence) : EbnAnimationIdentifier {
    
    if( isMultiAnim( animId ) ){
      const count = this._animCounters.get(animId)
      const size = this._animSizes.get(animId)
      let c = count;

      // if(ebn_market.currentMarketEBN == "mx" && ebn_state.currentCharacter == "zoe") {
      //   count !== 0 ? c = count + 1 : c = count;
      // } else {
      //   c = count;
      // }

      if(ebn_state.forceMultipleAnimation !== -1) c = ebn_state.forceMultipleAnimation
      //console.log('resolve multi anim', animId, c, size );

      this._animCounters.set(animId, (c+1) % size)
      // console.log((c+1) % size)
      return EbnAnimationSequence_getAnimationAt( animId, c)
    } 
    return animId
  }
  
  
  playSpeech(index : number): Promise<void> {
    const animId = EbnAnimationSequence_getAnimationAt( EbnAnimationMultiSequence.SPEECH, index )
    this.model.animator.playAnimation( this.audioLib.getSequence(animId) )
    return this.audioLib.play( animId )
  }

  
  public async loadAR() : Promise<void>{
    
    await this.scene.arview.loadAndStart()

/////////////////
//////////////////////////////////////////////////////
///////////////////////////////
/////
//////////////

    TrackingEbn.loadAR(ebn_state.currentCharacter)
  }

  public load() : Promise<void>{
    
    if( this._loadPromise === null ){
      this._loadPromise = Promise.all([
        this.audioLib.load(),
        this.model.load(),
        this.interaction.load()
      ]).then()
    }
    return this._loadPromise
    
  }

  
  rttRender() {
    if( this.readyToRender ){
      this.model.rttRender()
      this.interaction.rttRender()
    }
  }

  
  render() {
    if( this.readyToRender ){
      if(this.interaction.renderBefore) 
        this.interaction.render()
      this.model.render()
      if(!this.interaction.renderBefore) 
        this.interaction.render()
    }
  }

  private onStateChange(state:State<ArStateContext, ArStateEvent, ArState>){

    if( this._active ){
      
      const tval = state.value as ArStateValue | any
      let val: ArStateValue

      if( tval.waiting )
        val = 'waiting'
      else 
        val = tval

      
      ebn_state.setCharacterState(val)
      ebn_state.setCharacterCompletedUsps(state.context.usps)
      ebn_state.setActiveUsp( state.context.currentUsp )

      if( val === 'waiting'){
        this.interaction.enter()
      } else {
        this.interaction.leave()
      }
      
      
      if( val !== 'waiting' && val !== 'loading' && val !== 'inactive' ){
        this.arControler.start()
      } else {
        this.arControler.stop()
      }


    }
    
  }

}