








































import Delay from "@/core/Delay";
import TrackingEbn from "@/core/TrackingEbn";
import { EbnCharacters } from "@/store/models/EbnCharacters";
import ebn_state from "@/store/modules/ebn_state";
import viewport from "@/store/modules/viewport";
import { easeOutCubic } from "@/webgl/math/ease";
import gsap from "gsap";
import { Howl } from "howler";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import CarouselTuto from "./CarouselTuto.vue";
import CharacterPanel from "./CharacterPanel.vue";
import CharacterUI from "./CharacterUI.vue";
import CarouselProgress from "./EBNCarouselProgress.vue";

const PANEL_X: number[] = [-0.15, -0.05, 0, 0];

function getClientX(e: TouchEvent | MouseEvent): number {
  if (e instanceof MouseEvent) return e.clientX;
  else return e.touches[0].clientX;
}

const maxVsamples = 5;
@Component({
  components: {
    CharacterPanel,
    CharacterUI,
    CarouselTuto,
    CarouselProgress,
  },
})
export default class EBNCharacterCarousel extends Vue {
  public pos = 0;

  public drag: boolean = false;

  public tutoEnabled = false;

  showProgress: boolean = false;

  audio: Howl;

  lastDragX: number;
  lastDragTime: number;

  startDragX: number;
  startDragPos: number;

  velocity: number;
  vsamples: number[];

  musicStarted: boolean = false;

  get panelOffsetX(): number[] {
    return PANEL_X;
  }

  beforeMount() {
    if (ebn_state.isRevisit !== -1) {
      this.pos = ebn_state.isRevisit;
    }
  }

  mounted() {
    const el = this.$el as HTMLDivElement;

    this.audio = new Howl({
      src: `/assets/medias/carousel-loop.mp3`,
      loop: true,
      preload: true
    });

    ebn_state.setBackToCarousel(false);

    el.addEventListener("touchstart", this.onTouchStart);
    el.addEventListener("mousedown", this.onTouchStart);

    window.addEventListener("touchend", this.onTouchEnd);
    window.addEventListener("mouseup", this.onTouchEnd);

    window.addEventListener("mousemove", this.onTouchMove);
    window.addEventListener("touchmove", this.onTouchMove);

    this.vsamples = [];

    setTimeout(() => {
      this.tutoEnabled = ebn_state.isRevisit === -1;
      this.showProgress = ebn_state.isRevisit > -1;
      this.onPosChange();
    }, 2500);

    if(ebn_state.isRevisit > 1) {
      this.audio.play();
      this.audio.fade(0, 1, 1500);
      this.musicStarted = true;
    }
  }

  async selectChar() {
    this.audio.fade(1, 0, 1000);
    await Delay(1000);
    this.audio.stop();
  }

  destroy() {
    gsap.killTweensOf(this);

    const el = this.$el as HTMLDivElement;
    el.removeEventListener("touchstart", this.onTouchStart);
    el.removeEventListener("mousedown", this.onTouchStart);

    window.removeEventListener("touchend", this.onTouchEnd);
    window.removeEventListener("mouseup", this.onTouchEnd);

    window.removeEventListener("mousemove", this.onTouchMove);
    window.removeEventListener("touchmove", this.onTouchMove);
  }

  get characters() {
    return EbnCharacters;
  }

  @Watch("pos")
  onPosChange() {
    if (Math.abs(this.pos) > 0.8) {
      // if (this.tutoEnabled) TrackingEbn.swipe();
      this.tutoEnabled = false;
      this.showProgress = true;
    }
  }

  getPosAt(i: number) {
    const nchar = EbnCharacters.length;
    let p = i - this.pos;
    const sign = p < 0 ? -1 : 1;
    p = Math.abs(p) % EbnCharacters.length;
    p *= sign;

    if (p > nchar / 2) p -= nchar;
    else if (p < -nchar / 2) p += nchar;

    return p;
  }

  get getSelected() {
    return Math.abs(this.pos) % EbnCharacters.length;
  }

  sampleVelocity() {
    this.vsamples.push(this.velocity);
    while (this.vsamples.length > maxVsamples) {
      this.vsamples.shift();
    }
  }

  getRelaseVelocity() {
    if (this.vsamples.length === 0) return 0;

    let mean = 0;
    for (const v of this.vsamples) {
      mean += v;
    }
    return mean / this.vsamples.length;
  }

  onTouchStart(e: TouchEvent | MouseEvent) {
    if (!this.musicStarted) {
      this.audio.play();
      this.audio.fade(0, 1, 1500);
      this.musicStarted = true;
    }
    gsap.killTweensOf(this);
    this.vsamples.length = 0;
    this.drag = true;
    this.startDragX = getClientX(e) / viewport.windowWidth;
    this.lastDragX = this.startDragX;
    this.lastDragTime = performance.now();
    this.startDragPos = this.pos;
  }

  onTouchEnd() {
    this.drag = false;
    const maxV = this.getRelaseVelocity();

    if (maxV > 0.001) this.goToPos(Math.round(this.startDragPos) - 1);
    else if (maxV < -0.001) this.goToPos(Math.round(this.startDragPos) + 1);
    else this.goToPos(Math.round(this.pos));
  }

  onTouchMove(e: TouchEvent | MouseEvent) {
    if (this.drag) {
      const px = getClientX(e) / viewport.windowWidth;
      const delta = this.startDragX - px;
      this.pos = this.startDragPos + delta;

      const now = performance.now();
      const dt = now - this.lastDragTime;
      const dx = px - this.lastDragX;
      this.velocity = dx / dt;
      this.sampleVelocity();

      this.lastDragX = px;
      this.lastDragTime = now;

    } else {
      this.velocity = 0;
    }
  }

  goToPos(p: number) {
    gsap.killTweensOf(this);
    gsap.to(this, { pos: p, duration: 0.5, ease: easeOutCubic });
  }
}
