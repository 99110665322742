import Paths from "@/core/Paths";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import IRenderable from "@/webgl/lib/nanogl-gltf/lib/renderer/IRenderable";
import Scene from "@/webgl/Scene";
import Camera from "nanogl-camera";
import GLConfig from "nanogl-state/config";
import GltfModule from "../GltfModule"
import CharacterController from "./CharacterController"
import { ICharacterInteraction } from "./Characterinteraction"
import ebn_state from "@/store/modules/ebn_state";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Material from "@/webgl/lib/nanogl-gltf/lib/elements/Material";
import Input, { IInputParam, Uniform } from "nanogl-pbr/Input";
import { StandardPass } from "nanogl-pbr/StandardPass";
import Node from '../../lib/nanogl-gltf/lib/elements/Node'
import { vec3 } from "gl-matrix";
import UnlitMaterial from "@/webgl/lib/nanogl-gltf/lib/extensions/KHR_materials_unlit/UnlitMaterial";
import UnlitPass from "nanogl-pbr/UnlitPass";
import Chunk from "nanogl-pbr/Chunk";
import Program from "nanogl/program";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import ebn_market from '@/store/modules/ebn_market';

class MixTexColor extends Chunk {
  progress: number;
  mixMax:vec3
  constructor(){
    super(true, true)
    this.progress = 0
    this.mixMax = vec3.create()
  }
  
  setup( prg:Program){
    prg.upp( this.progress )
    prg.uMixMax( this.mixMax )
  }
  
  protected _genCode(slots: ChunksSlots): void {
    slots.add( 'pf', 'uniform float upp;')
    slots.add( 'pf', 'uniform vec3 uMixMax;')
    slots.add( 'postf', 'FragColor.rgb = mix(uMixMax, _baseColor, upp);')
  }
  
}

export default class ZoeInteraction implements ICharacterInteraction {
  public renderBefore:boolean = true
  effectGltf : GltfModule
  scene: Scene;
  ready: boolean;
  controler: CharacterController;
  prevTime:number
  show:boolean
  t4MixColor:MixTexColor
  t2MixColor:MixTexColor

  played:boolean[] = [false, false, false, false]

init(  ctrl:CharacterController ):void {
  this.controler = ctrl
  this.scene = ctrl.scene
  this.effectGltf = new GltfModule( this.scene, Paths.resolve( 'assets/webgl/gltf/zoe_fx.glb' ) )
  }
  enter(): void {
    this.prevTime = -10000
  }
  leave(): void {0}
  dispose(): void {
    this.scene.camera.remove( this.effectGltf.gltf.root )
    for (let i = 0; i < this.played.length; i++) {
      this.played[i] = false      
    }

  }
  preRender(): void {
    // vec3.copy(this.effectGltf.gltf.root.position, this.controler.model.root.position)
    // this.effectGltf.gltf.root.lookAt(this.scene.camera.position)
    // this.effectGltf.gltf.root.invalidate()
    let curr = ebn_state.currentZoe
    if((curr > 0 && curr !== 0) && ebn_market.currentMarketEBN == "mx") curr = curr + 1;
    const triggName = 'trigger_' + (curr + 1)

    const time = ebn_state.animationCurrentTime
    const anim = this.effectGltf.gltf.getElementByName<GltfTypes.ANIMATION>(GltfTypes.ANIMATION, triggName)
    if(anim && time) {
      
      if(!this.played[curr]) {
        this.show = true
        anim.evaluate(time)
        
        switch(curr) {
          case 0:
            this.renderBefore = false
          break;
          case 1:
            this.renderBefore = false
            this.animSun()
          break
          case 2:
            this.renderBefore = false
          break;
          case 3:
            this.renderBefore = true
            this.animNight()
          break
        }

        if(time < this.prevTime) this.played[curr] = true
        this.prevTime = time
      }
    }
  }
  
  rttRender(): void {0}
  render(){
    const camera = this.scene.camera
    this.renderGltfScene(camera, Masks.OPAQUE)
    this.renderGltfScene(camera, Masks.BLENDED)
    this.renderGltfScene(camera, Masks.BLENDED2)
  }

  renderGltfScene(camera: Camera, mask: Masks, passId: Passes = Passes.DEFAULT, cfg?: GLConfig) {
    let curr = ebn_state.currentZoe
    if((curr > 0 && curr !== 0) && ebn_market.currentMarketEBN == "mx") curr = curr + 1;
    const triggName = 'trigger_' + (curr + 1)

    if(this.played[curr]) return

    const rlist :IRenderable[] = this.effectGltf.gltf.renderables.filter((r:IRenderable) => (r.node._parent as Node).name === triggName || (r.node._parent._parent as Node).name === triggName)
    // if( this.zsorting ){
    //   rlist = this.sortRenderables( camera, this.gltf.renderables, mask === Masks.BLENDED )
    // } else {
    //   rlist = this.gltf.renderables

    // }

    // const rlist :IRenderable[] = this.effectGltf.gltf.renderables.filter((r:IRenderable) => (r.node.name === 'Plane'))

    for (const renderable of rlist) {
      renderable.render(this.scene, camera, mask, passId, cfg)
    }
  }

  async load(): Promise<void> {
    await this.effectGltf.load()
    this.ready = true
    this.setupMaterials()
    this.scene.camera.add( this.effectGltf.gltf.root )
    this.effectGltf.gltf.root.z = -5
    const target = vec3.create()
    vec3.set(target, 0, 0, -1)
    this.effectGltf.gltf.root.lookAt(target)
    this.effectGltf.gltf.root.invalidate()
  }
  
  setupMaterials() {

    const mats = this.effectGltf.gltf.materials
    const gl = this.scene.gl

    for (const m of mats) {

      if( m.name.includes('additive')){
        
        const pass = (m as Material).materialPass as StandardPass
        pass.mask = Masks.BLENDED
        pass.glconfig
          .enableBlend()
          .blendFunc(gl.ONE, gl.ONE)
          .enableDepthTest(false)
          .depthMask(false)

      }

      if( m.name.includes('multiply')){
        const pass = (m as Material).materialPass as UnlitPass
        pass.mask = Masks.BLENDED
        pass.glconfig
          .enableBlend()
          .blendFunc(gl.ZERO, gl.SRC_COLOR)

      }

      //Night
      const material:Material = this.effectGltf.gltf.getElementByName(GltfTypes.MATERIAL, "night_multiply") as UnlitMaterial
      this.t4MixColor = new MixTexColor();
      (material.materialPass as UnlitPass).inputs.add(this.t4MixColor)
      vec3.set(this.t4MixColor.mixMax, 1, 1, 1)

      this.t2MixColor = new MixTexColor();
      vec3.set(this.t2MixColor.mixMax, 0, 0, 0)

      //Sun
      const materialSunAdd:Material = this.effectGltf.gltf.getElementByName(GltfTypes.MATERIAL, "sun_additive") as UnlitMaterial
      (materialSunAdd.materialPass as UnlitPass).inputs.add(this.t2MixColor)
      const materialSunRAdd:Material = this.effectGltf.gltf.getElementByName(GltfTypes.MATERIAL, "ray_additive") as UnlitMaterial
      (materialSunRAdd.materialPass as UnlitPass).inputs.add(this.t2MixColor)

      const passAlpha = (this.effectGltf.gltf.getElementByName(GltfTypes.MATERIAL, "sun_normal") as UnlitMaterial).materialPass as UnlitPass
      passAlpha.alphaFactor.attach(new Uniform("alphaFactor", 1))

    }
  }

  animNight() {
    const trans = this.effectGltf.gltf.getElementByName(GltfTypes.NODE, "plane_ctrl_opacity").scale[0]
    this.t4MixColor.progress = trans * 0.01
  }

  animSun() {
    const trans = this.effectGltf.gltf.getElementByName(GltfTypes.NODE, "sun_ctrl_opacity").scale[0]
    this.t2MixColor.progress = trans * 0.01
    const passAlpha = (this.effectGltf.gltf.getElementByName(GltfTypes.MATERIAL, "sun_normal") as UnlitMaterial).materialPass as UnlitPass
    ((passAlpha.alphaFactor as Input).param as Uniform)._value[0] = trans * 0.01
  }
}