


























import ebn_state from "@/store/modules/ebn_state";
import Vue from "vue";
import Component from "vue-class-component";
import PendingBar from "./PendingBar.vue";
import gsap, { Sine, Back } from "gsap";
import Delay from "@/core/Delay";
import { LocaleMessage } from "vue-i18n";
import ebn_market from '@/store/modules/ebn_market';
import { Howl } from 'howler';

@Component({
  components: {
    PendingBar,
  },
})
export default class EBNLoading extends Vue {
  timeline: gsap.core.Timeline;
  audio: Howl

  get currentChar() {
    return ebn_state.currentCharacter;
  }

  get loading(): LocaleMessage {
    return this.$t("loading");
  }

  get mx(): boolean {
    return ebn_market.currentMarketEBN == "mx";
  }

  get charname(): string {
    return this.$i18n
      .t(`${this.currentChar}.name`)
      .toString()
      .toUpperCase()
      .split("")
      .map((c) => `<span><span>${c}</span></span>`)
      .join("");
  }

  get gender(): string {
    return this.$i18n.t(`${this.currentChar}.gender`).toString();
  }

  get constance() {
    return ebn_state.currentCharacter === "constance";
  }

  buildTimeline() {
    const gender = this.$el.querySelector(".gender-wrapper");
    // const genderInside = this.$el.querySelector(".gender");
    const charname = this.$el.querySelectorAll(".charname > span");
    // const charnameInside = this.$el.querySelectorAll(".charname > span > span");
    const soundicon = this.$el.querySelectorAll(".soundicon");
    const turnup = this.$el.querySelectorAll(".turnup");
    const loading = this.$el.querySelectorAll(".loading-text");
    const pbar = this.$el.querySelectorAll(".pbar");

    const LOADING_UI_TIMESCALE = 1;

    this.timeline = gsap.timeline({ paused: true });
    this.timeline
      .fromTo(
        gender,
        {
          rotation: 20,
          y: 30,
          opacity: 0,
        },
        {
          y: 0,
          duration: 1,
          opacity: 1,
          rotation: 0,
          delay: 0.1 * LOADING_UI_TIMESCALE,
        }
      )
      .fromTo(
        charname,
        { rotation: 0, y: 30 },
        {
          duration: 0.01,
          rotation: "random(-30, 30)",
          y: 30,
        },
        "<"
      )
      .to(
        charname,
        {
          duration: 1 * LOADING_UI_TIMESCALE,
          rotation: 0,
          y: 0,
          stagger: 0.03 * LOADING_UI_TIMESCALE,
          ease: Back.easeOut,
        },
        "<"
      )
      .fromTo(
        charname,
        { opacity: 0 },
        {
          duration: 0.1 * LOADING_UI_TIMESCALE,
          opacity: 1,
          stagger: 0.03,
          ease: Sine.easeOut,
        },
        "<"
      )
      .fromTo(
        soundicon,
        { opacity: 0 },
        {
          duration: 1 * LOADING_UI_TIMESCALE,
          opacity: 1,
          ease: Sine.easeOut,
          delay: 0.25 * LOADING_UI_TIMESCALE,
        },
        "<"
      )
      .fromTo(
        turnup,
        { opacity: 0, y: 30 },
        {
          duration: 0.5 * LOADING_UI_TIMESCALE,
          opacity: 1,
          y: 0,
          ease: Sine.easeOut,
          delay: 0.3 * LOADING_UI_TIMESCALE,
        },
        "<"
      )
      .fromTo(
        loading,
        { opacity: 0 },
        {
          duration: 0.5 * LOADING_UI_TIMESCALE,
          opacity: 1,
          ease: Sine.easeOut,
          delay: 0.5 * LOADING_UI_TIMESCALE,
        },
        "<"
      )
      .fromTo(
        pbar,
        { y: 30 },
        {
          duration: 0.5 * LOADING_UI_TIMESCALE,
          y: 0,
          ease: Sine.easeOut,
          delay: 0.51 * LOADING_UI_TIMESCALE,
        },
        "<"
      );

    // gsap.to(genderInside, {
    //   duration: 0.6 + Math.random() * 0.6,
    //   y: 2 + Math.random() * 2,
    //   repeat: -1,
    //   yoyo: true,
    //   repeatDelay: 0,
    //   ease: Sine.easeInOut,
    // });

    // Array.from(charnameInside).forEach((c, i) =>
    //   gsap.to(c, {
    //     duration: 0.8,
    //     y: 6,
    //     repeat: -1,
    //     yoyo: true,
    //     repeatDelay: 0,
    //     delay: i * 0.1,
    //     ease: Sine.easeInOut,
    //   })
    // );
  }

  async mounted() {
    this.buildTimeline();
    await Delay(500);
    this.timeline.play();
    this.audio = new Howl({
      src: `/assets/medias/loading-${this.currentChar}.mp3`,
      loop: true,
      preload: true
    });
    this.audio.play();
  }

  beforeDestroy() {
    this.audio.fade(1, 0, 500);
    setTimeout(() => {
      this.audio.stop();
    }, 500)
  }
}
