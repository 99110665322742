/* eslint-disable @typescript-eslint/no-explicit-any */
import Delay from "@/core/Delay";
import type CharacterController from "@/webgl/entities/ebn/CharacterController";
import ebnCharacterControllers from "@/webgl/entities/ebn/Characters";
import { assign, createMachine, DoneInvokeEvent, interpret, InvokeConfig, SingleOrArray, TransitionConfig } from "xstate";
import { EbnAnimationIdentifier, EbnAnimationMultiSequence, EbnAnimationSequence } from "../models/EbnAnimationSequence";
import ebn_market from "../modules/ebn_market";
import ebn_state from "../modules/ebn_state";


export type ArStateValue =  
 'inactive'           |
 'loading'            |
 'endloading'         |
 'tap_to_place'       |
 'intro'              |
 'trigger'            |
 'tuto'               |
 'waiting'            |
 'success'            |
 'failure'            |
 'speech'             |
 'disclaimer_pause'   |
 'outro'              |
 'end'                |
 'disposed' 



// export interface ArStateSchema {
//   states: {
//     inactive: {}
//     loading: {}
//     tap_to_place: {}
//     intro: {}
//     trigger: {}
//     waiting: {}
//     success: {}
//     failure: {}
//     speech: {}
//     outro: {}
//     end: {}
//   }
// }


export interface ArStateContext {
  currentUsp: number
  usps: boolean[]
  hasFailureAnim: boolean
  animationController: CharacterController
  tutoSeen:boolean
  loaded:boolean
}


export type ArState =  {
  value: ArStateValue
  context: ArStateContext
}



export type SuccessEvent = {
  type: "ACTION_SUCCESS"
  usp?: number
}

export type ArStateEvent = {
  type: "START" | "PLACED" | "ACTION_FAILURE" | "LOAD" | "EXIT_TUTO" | "CLOSE_DISCLAIMER" | "DISPOSE"
} | SuccessEvent



function lastUsp(ctx: ArStateContext): boolean {

    const isLast = !ctx.usps.some(completed => !completed)
    // console.log("is alst usp", ctx.usps, isLast );
    return isLast
  
  // return ctx.remainingUsps <= 0
}

function tutoNotSeen(ctx: ArStateContext): boolean {
  return !ctx.tutoSeen
}

function ctxHasNoFailureAnim(ctx: ArStateContext): boolean {
  return !ctx.hasFailureAnim
}

function disclaimerOpened(): boolean {
  return ebn_state.disclaimerOpened
}

function motivational(): boolean {
  return ebn_state.currentCharacter === "zoe" || ebn_state.currentCharacter === "robert"
}

function notMotivational(): boolean {
  return ebn_state.currentCharacter === "joel" || ebn_state.currentCharacter === "constance"
}


function firstAvailableUsp(ctx: ArStateContext): number {
  const index = ctx.usps.findIndex( v=>!v )
  return ( index>-1) ? index: 0
}


function invokeAnimation(anim: EbnAnimationSequence, onDone? : string | SingleOrArray<TransitionConfig<ArStateContext, DoneInvokeEvent<any>>> ): InvokeConfig<ArStateContext, ArStateEvent> {
  return {
    src: {
      type: 'playAnimation',
      anim
    },
    onDone
  }
}

function invokeSpeechAnimation(onDone? : string | SingleOrArray<TransitionConfig<ArStateContext, DoneInvokeEvent<any>>> ): InvokeConfig<ArStateContext, ArStateEvent> {
  return {
    src: {
      type: 'playSpeechAnimation',
    },
    onDone
  }
}

// Edit your machine(s) here
const EbnArStateMachine = createMachine<ArStateContext, ArStateEvent, ArState>({

  id: "ebn_arstate",

  // initial: "waiting",
  // initial: "tuto",
  initial: "inactive",

  context: {
    // remainingUsps: 1,
    currentUsp: 0,
    usps: [false, false, false],
    hasFailureAnim: true,
    animationController: null,
    tutoSeen: false,
    loaded: false
  },

  on:{
    DISPOSE: 'disposed'
  },

  states: {

    inactive: {
      on: { LOAD: "loading" }
    },


    loading: {
      invoke: {
        src: 'startLoad',
        onDone: 'endloading'
        // onDone: 'waiting'
      }
    },


    endloading: {
      invoke: {
        src: 'endLoad',
        onDone: 'tap_to_place'
        // onDone: 'waiting'
      }
    },

    tap_to_place: {
      on: { PLACED: "intro" }
    },

    intro: {
      invoke: invokeAnimation( EbnAnimationIdentifier.INTRO, "trigger" )
    },

    trigger: {
      invoke: invokeAnimation( EbnAnimationMultiSequence.TRIGGER,[
        { target: 'tuto', cond: tutoNotSeen },
        { target: 'waiting' },
      ] )
    },

    tuto: {
      on: {
        EXIT_TUTO: {
          target: 'waiting'
        }
      },
      exit: ['markTutoAsSeen'],
      invoke: invokeAnimation( EbnAnimationIdentifier.WAITING_1 )
    },

    waiting: {
      on: {
        ACTION_SUCCESS: {
          target: 'success',
          actions: ['setUspOnSuccess']
        },
        ACTION_FAILURE: {
          target: 'failure',
          actions: ['setUspOnSuccess']
        },
      },

      initial: 'loop',
      states: {
        loop: {
          invoke: invokeAnimation( EbnAnimationMultiSequence.WAITING )
        }
      },
    },

    success: {
      invoke: invokeAnimation( EbnAnimationMultiSequence.SUCCESS, "speech" )
    },

    failure: {
      // on: {
      //   '': { target: 'waiting', cond: ctxHasNoFailureAnim },
      // },
      always: { target: 'speech', cond: ctxHasNoFailureAnim },
      invoke: invokeAnimation( EbnAnimationMultiSequence.FAILURE, "speech" )
    },

    speech: {
      entry: ['completeCurrentUsp'],
      
      invoke: invokeSpeechAnimation([
        { target: 'disclaimer_pause', cond: disclaimerOpened },
        { target: 'outro', cond: lastUsp },
        { target: 'trigger' }
      ] )
    },

    disclaimer_pause: {
      on:{
        CLOSE_DISCLAIMER: [
          { target: 'outro', cond: lastUsp },
          { target: 'trigger' }
        ]
      },
      invoke: invokeAnimation( EbnAnimationIdentifier.IDLE )
    },

    outro: {
      invoke: invokeAnimation( EbnAnimationIdentifier.OUTRO, 'end' )
    },

    end: {
      invoke: invokeAnimation( EbnAnimationIdentifier.IDLE ),
      after: {
        1000: 'disposed'
      }
    },

    disposed:{

      entry:assign<ArStateContext, ArStateEvent>({
        animationController:()=>null
      }),

      type: 'final'
    }

  },

}, {

  actions: {

    setUspOnSuccess: (context: ArStateContext, event: ArStateEvent) => {
      const usp = (event as SuccessEvent).usp
      if (usp !== undefined)
        context.currentUsp = usp
      else
        context.currentUsp = firstAvailableUsp( context )
    },

    completeCurrentUsp: (context: ArStateContext) => {
      context.usps[context.currentUsp] = true
    },

    markTutoAsSeen: (context: ArStateContext) => {
      context.tutoSeen = true
    }

  },


  services: {

    startLoad: async (context: ArStateContext): Promise<void> => {
      await ebnCharacterControllers.lazyLoad()
      await context.animationController.load()
      await context.animationController.loadAR()
      context.loaded = true
    },

    endLoad: async (): Promise<void> => {
      await Delay(4000)
    },

    playAnimation: async (context: ArStateContext, event: ArStateEvent, { src }): Promise<void> => {
      const animId = src.anim as EbnAnimationSequence
      return context.animationController.playAnimation( animId )
    },

    playSpeechAnimation: async (context: ArStateContext): Promise<void> => {
      return context.animationController.playSpeech( context.currentUsp )
    }


  }

});


export default EbnArStateMachine






/// ======================================================================
/// ======================================================================
/// ======================================================================

// const zoe_machine = EbnArStateMachine.withContext({
//   ...EbnArStateMachine.context,
//   usps: [false, false, false, false]
// })


// const service = interpret(zoe_machine)
//   .onTransition((state) => {
//     console.log(state.value, state.context.currentUsp);
//   })
//   .onEvent((e) => {
//     console.log(e);
//   });


// async function test() {
//   service.start();
//   await Delay(2000)
//   service.send("PLACED");
// }

// test()



