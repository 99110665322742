






















import { LocaleMessage } from "vue-i18n";
import Vue from "vue";
import Component from "vue-class-component";
import Paths from '@/core/Paths';
import ebn_market from '@/store/modules/ebn_market';
@Component
export default class EBNDesktopPage extends Vue {
  get title(): LocaleMessage {
    return this.$t("desktop.title");
  }
  get bottom(): LocaleMessage {
    return this.$t("desktop.bottom");
  }
  get carURL(): string {
    if(ebn_market.currentMarketEBN == "mx") {
      return Paths.resolve(`assets/images/mx/intro-cover-car_mx.png`);
    }

    return Paths.resolve(`assets/images/intro-cover-car.png`);
  }
}
