










import ebn_state from "@/store/modules/ebn_state";
import gsap, { Expo } from "gsap";
import Vue from "vue";
import Component from "vue-class-component";
import { TranslateResult } from "vue-i18n";
import { Prop, Watch } from "vue-property-decorator";

export enum EBNHintType {
  SCAN_AREA = "scan_area",
  TAP_TO_PLACE = "tap_to_place",
}

@Component
export default class EBNHintCard extends Vue {
  @Prop({ type: String, required: false, default: null })
  type: EBNHintType | null;

  timeline: gsap.core.Timeline;

  get icon(): string {
    switch (this.type) {
      case EBNHintType.SCAN_AREA:
        return "ar_logo";
      case EBNHintType.TAP_TO_PLACE:
        return "tap";
    }
    return "ar_logo";
  }

  get text(): TranslateResult {
    if (this.type === null) return "";

    switch (this.type) {
      case EBNHintType.SCAN_AREA:
        //return this.$t(`scan_area`);
        break;
      case EBNHintType.TAP_TO_PLACE:
        return this.$t(`${ebn_state.currentCharacter}.hint`);
    }

    return this.$t(`hints.${this.type}`);
  }

  @Watch("type")
  onTypeChange(type: string) {
    if (type !== null) {
      this.$nextTick(() => {
        if (!this.timeline) this.buildTimeLine();
        this.timeline.play();
      });
    }
  }

  buildTimeLine() {
    const wrapper = this.$el;
    const icon = this.$el.querySelector(".icon");
    const text = this.$el.querySelector("div");
    this.timeline = gsap.timeline();
    this.timeline
      .fromTo(
        wrapper,
        {
          scale: 0,
        },
        {
          duration: 0.5,
          scale: 1,
          ease: Expo.easeOut,
        }
      )
      .fromTo(
        icon,
        {
          scale: 0,
        },
        {
          duration: 0.5,
          delay: 0.1,
          scale: 1,
          ease: Expo.easeOut,
        },
        "<"
      )
      .fromTo(
        text,
        {
          scale: 0,
        },
        {
          duration: 0.5,
          delay: 0.2,
          scale: 1,
          ease: Expo.easeOut,
        },
        "<"
      );
  }
}
