












































import TrackingEbn from "@/core/TrackingEbn";
import ebnCharacterControllers from "@/webgl/entities/ebn/Characters";
import { clamp01 } from "@/webgl/math";
import { Howler } from "howler";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import EBNButton from "../EBNButton/EBNButton.vue";
import VolumeMeter from "./VolumeMeter";

@Component({
  components: {
    EBNButton,
  },
})
export default class ConstanceInteractionView extends Vue {
  public progressRadius = 78;
  public progress = 0.35;

  accum = 0;
  volumeMeter: VolumeMeter;
  volumeCheckInterval: number;
  done = false;

  failureTO: NodeJS.Timeout;

  get circleDashOffset() {
    return Math.PI * 2 * this.progressRadius * (1.0 - this.progress);
  }

  get isComplete() {
    return this.progress > 0.98;
  }

  created() {
    // window.addEventListener( 'mousemove', (e)=>{
    //   this.progress = e.clientX/window.innerWidth
    // } )

    this.volumeMeter = new VolumeMeter();
    this.volumeMeter.init();
    // this.volumeMeter.onChange.on( ()=>this.progress = this.volumeMeter.volume )
    if (this.failureTO) clearTimeout(this.failureTO);
    this.failureTO = setTimeout(this.onFailure, 12000);

    this.volumeCheckInterval = window.setInterval(this.checkVolume, 1 / 10);
  }

  checkVolume() {
    this.volumeMeter.volumeTimeout();
    let v = this.volumeMeter.volume * 0.01;
    v = Math.min(1.0, v);
    this.accum -= 0.015 * 0.8;
    this.accum += v * 0.003 * 30;
    this.accum = clamp01(this.accum);
    this.progress = this.accum;
  }

  @Watch("isComplete")
  onComplete(n: boolean) {
    if (n && !this.done) {
      if (this.failureTO) clearTimeout(this.failureTO);
      this.done = true;
      TrackingEbn.energize();
      setTimeout(() => this.onSuccess(), 1000);
    }
  }

  destroyed() {
    this.volumeMeter.dispose();
    window.clearInterval(this.volumeCheckInterval);
    if (this.failureTO) clearTimeout(this.failureTO);
  }

  onSuccess() {
    ebnCharacterControllers.markAsSuccess();
  }

  onFailure() {
    ebnCharacterControllers.markAsFailure();
  }
}
