






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EBNPopin extends Vue {
  @Prop({ type: Boolean, required: true }) popinVisible!: boolean;

  // get isVisible() {
  //   return this.popinVisible;
  // }
}
