

type Detail = {
  'data-dtm':string
  alt? :string
}


export default class TrackingEbn {

  
  static track( identifier:string, detail?:Detail ){
    window._satellite?.track( identifier, detail )
  }
  
  static trackData( identifier:string, dtm: string, alt?:string ){
    const detail: Detail = {
      'data-dtm' : dtm,
      alt
    }
    console.log('track', identifier, dtm, alt)
    window._satellite?.track( identifier, detail )
  }

  static pageView() {
    window._satellite?.track("page-view")
  }

  //Carousel
  static swipe() {
    this.trackData('swipe-link', 'electric adventure', 'swipe:change character')
  }

  static meetBtn(char:string) {
    this.trackData('button-link', 'electric adventure', 'meet ' + char)
  }

  static changeChar(id:number) {
    this.trackData('button-link', 'electric adventure:scroller dot:', id.toString())
  }

  static clickCharBar(char:string) {
    this.trackData( 'icon-link' , `global nav:characters`, char )
  }

  // Menu
  static toggleMenu(on: boolean) {
    const isOn:string = on ? 'on' : 'off';
    this.trackData('icon-link', 'global nav', 'expand menu ' + isOn);
  }

  static toggleSound(on:boolean) {
    const isOn:string = on ? 'on' : 'off'
    this.trackData('icon-link', 'global nav', 'sound ' + isOn)
  }

  static globalNav(alt:string) {
    this.trackData( 'button-link' , `global nav`, alt )
  }

  static menuButton(alt:string) {
    this.trackData( 'button-link' , `global nav`, alt )
  }

  static toggleCharBar(on:boolean) {
    this.trackData( 'button-link' , `global nav`, 'characters ' + on ? 'expand' : 'close' )
  }

  //Footer
  static toggleSubtitles(on:boolean) {
    const isOn = on ? 'on' : 'off'
    this.trackData( 'icon-link' , `nav secondary`, 'captions ' +  isOn)
  }
  static share() {
    this.trackData( 'icon-link' , `nav secondary`, 'share' )
  }

  static changeLanguage() {
    this.trackData('icon-link', 'global nav', 'language')
  }

  // Car features
  static openFeature(name: string) {
    this.trackData('button-link', name, 'vehicle feature')
  }

  static prevFeature(name: string) {
    this.trackData('arrow-prev', `${name}:modal:vehicle feature`, 'arrow prev')
  }

  static nextFeature(name: string) {
    this.trackData('arrow-next', `${name}:modal:vehicle feature`, 'arrow next')
  }

  //AR
  static loadAR(char:string) {
    this.trackData('load-ar', char)
  }

  static tapToLearn(char:string, title:string) {
    this.trackData('expand-link' , char, title)
  }

  static skipTuto(char:string) {
    this.trackData('button-link', char + ':tutorial', 'skip')
  }

  static errorButtons(char:string, cont:boolean) {
    const isCont:string = cont ? 'continue anyway' : 'go to chevrolet.com'
    this.trackData('button-link',  char + ':error modal', isCont)
  }

  static fpsPopin() {
    window._satellite?.track("error-modal", {'error-type' : 'fps-warning'})
  }

  //End screen
  static endLink(label:string) {
    this.trackData('button-link', 'electric adventure', label);
  }

  static playVideo(char:string, label:string) {
    this.trackData('icon-link', char, label);
  }

  //Robert
  static throwBerry() {
    this.trackData('throw-berry', 'robert');
  }

  //Constance
  static energize() {
    this.trackData('energize', 'constance')
  }

  //Joel
  static balance() {
    this.trackData('balance', 'joel')
  }

  //Zoe
  static zoeInteraction(type:string) {
    this.trackData(type, 'zoe')
  }
}