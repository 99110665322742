














import SchemeColoredMixin from "@/mixins/SchemeColored";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  mixins: [SchemeColoredMixin],
})
export default class EBNButton extends Vue {
  @Prop({ type: String, required: false }) text!: string;

  @Prop({ type: String, required: false }) icon!: string;

  @Prop({ type: String, required: false }) href!: string;

  @Prop({ type: Boolean, required: false, default: false }) light!: boolean;

  onClick() {
    this.$emit("click");
    if (this.href && this.href.length > 0) {
      window.open(this.href, "_blank").focus();
      // window.location.href = this.href;
    }
  }
}
