

export function clamp(n:number):number{
  return Math.min( 1.0, Math.max( 0.0, n ) );
}

export default function Stagger(p:number, count : number, amount = .5 ): number[] {

  const slope = 1 + (count-1)*amount

  const res = []
  const doy = (slope-1)/(count-1)

  for (let i = 0; i < count; i++) {

    const v = p * slope - i*doy
    res[i] = clamp(v) 
  }

  return res;
  
}