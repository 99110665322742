












import ebn_state from '@/store/modules/ebn_state'
import Vue from 'vue'
import Component from 'vue-class-component'
import ConstanceInteractionView from './Constance.vue'
import GenericInteractionView from './Generic.vue'
import ZoeInteractionView from './Zoe.vue'
import JoelInteractionView from './Joel.vue'
import RobertInteractionView from './Robert.vue'


@Component
export default class EBNInteractionViews extends Vue {

  get component(){
    switch (ebn_state.currentCharacter) {
      case 'zoe'      : return ZoeInteractionView
      case 'constance': return ConstanceInteractionView
      case 'joel'     : return JoelInteractionView
      case 'robert'   : return RobertInteractionView
    }
    return GenericInteractionView
  }

  get visible(): boolean {
    return ebn_state.characterState === 'waiting'
  }

}

