import ebn_state from "@/store/modules/ebn_state";
import ebnCharacterControllers from "./entities/ebn/Characters";
import IModule from "./IModule";
import Scene from "./Scene";
import { FpsSampler } from "./Time";


const FPS_THRESHOLD = 14


export default class EBNModule implements IModule {
  
  scene: Scene
  fpsSampler: FpsSampler



  init( scene: Scene): void {
    
    this.scene = scene
    this.scene.envRotation = 0
    
    ebnCharacterControllers.init(scene)
    this.fpsSampler  = new FpsSampler(150)

  }

  
  preRender(): void {
    this.fpsSampler.sampleFrame()
    const fps = this.fpsSampler.fps
    
    
    if ( fps <= FPS_THRESHOLD && !ebn_state.isFPSWarning) {
      ebn_state.setIsFPSWarning(true)
    }
    ebnCharacterControllers.update()
  }
  
  rttRender(): void {
    ebnCharacterControllers.rttRender()
  }
  
  render(): void {
    ebnCharacterControllers.render()
  }


  load(): Promise<void> {
    return Promise.resolve()
  }

}