




























import TrackingEbn from "@/core/TrackingEbn";
import SchemeColoredMixin from "@/mixins/SchemeColored";
import audio_state from "@/store/modules/audio_state";
import ebn_state from "@/store/modules/ebn_state";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  mixins: [SchemeColoredMixin],
})
export default class EBNBurgerMenu extends Vue {
  get expanded() {
    return (
      !ebn_state.sharePanelOpened &&
      !ebn_state.menuPanelOpened &&
      ebn_state.menuExpanded
    );
  }

  get closeIcon() {
    return (
      ebn_state.sharePanelOpened ||
      ebn_state.menuPanelOpened ||
      ebn_state.menuExpanded
    );
  }

  get menuOpen() {
    return ebn_state.menuPanelOpened;
  }

  get primary() {
    return ebn_state.sharePanelOpened || ebn_state.menuExpanded;
  }

  get grey() {
    return ebn_state.menuPanelOpened;
  }

  get burgerIcon() {
    return this.closeIcon ? "ebn-close" : "ebn-burger";
  }

  get isMuted() {
    return audio_state.isMuted;
  }

  get muteIcon(): string {
    return audio_state.isMuted ? "ebn-unmute" : "ebn-mute";
  }

  switchMute() {
    audio_state.isMuted ? audio_state.unmute() : audio_state.mute();
    TrackingEbn.toggleSound(!audio_state.isMuted);
  }

  onClickClose() {
    if (ebn_state.sharePanelOpened) {
      ebn_state.closeSharePanel();
      return;
    }

    if (ebn_state.menuPanelOpened) {
      ebn_state.closeMenuPanel();
      return;
    }
    TrackingEbn.toggleMenu(!this.expanded);

    if (this.expanded) {
      ebn_state.collapseMenu();
    } else {
      ebn_state.expandMenu();
    }
  }

  openMenuPanel() {
    TrackingEbn.menuButton("menu informations");

    ebn_state.collapseMenu();
    ebn_state.openMenuPanel();
  }
}
