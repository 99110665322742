

























import TrackingEbn from "@/core/TrackingEbn";
import ebn_state from "@/store/modules/ebn_state";
import ebnCharacterControllers from "@/webgl/entities/ebn/Characters";
import Vue from "vue";
import Component from "vue-class-component";
import ebn_market from '@/store/modules/ebn_market';
import { Watch } from 'vue-property-decorator';

type Button = {
  icon: string;
};

@Component
export default class EBNZoeInteractionView extends Vue {
  
  get buttons(): Button[] {
    const mxBtns = [
      { icon: "zoe-btn-rain" },
      { icon: "zoe-btn-wind" },
      { icon: "zoe-btn-sleep" },
    ];

    if(ebn_market.currentMarketEBN == "mx") {
      return mxBtns;
    }

    let btns = [
      { icon: "zoe-btn-rain" },
      { icon: "zoe-btn-sun" },
      { icon: "zoe-btn-wind" },
      { icon: "zoe-btn-sleep" },
    ];

    return btns;
  };

  get selectedIndex(): number {
    // if( ebn_state.characterState === 'waiting')
    //   return ebn_state.activeUsp
    return -1;
  }
  
  get mx(): boolean {
    return ebn_market.currentMarketEBN == "mx";
  }

  get completedUsps(): boolean[] {
    return ebn_state.completedUsps;
  }

  // @Watch("completedUsps")
  // checkMexUsps(n: boolean[]) {
  //   console.log(n)
  //   if((n[0] && n[1] && n[2]) && ebn_market.currentMarketEBN == "mx") {
  //     ebn_state.setCharacterState("outro");
  //   }
  // }

  onSelect(index: number) {
    if (!this.completedUsps[index]) {
      ebn_state.setForceMultipleAnimation(index);
      ebnCharacterControllers.markAsSuccess(index);
      ebn_state.setForceMultipleAnimation(-1);
      let trackType: string = "rain";

      if(ebn_market.currentMarketEBN == "mx") {
        switch (index) {
          case 1:
            trackType = "wind";
            break;
          case 2:
            trackType = "night";
            break;
        }
      } else {
        switch (index) {
          case 1:
            trackType = "sunlight";
            break;
          case 2:
            trackType = "wind";
            break;
          case 3:
            trackType = "night";
            break;
        }
      }

      TrackingEbn.zoeInteraction(trackType);
    }
  }
}
