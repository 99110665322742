
























































import { primaryClass } from "@/mixins/SchemeColored";
// import ebn_character_configs from "@/store/datas/ebn_character_configs";
import { EBNDisclaimerData } from "@/store/models/EBNDisclaimerData";
import ebn_state, { EBNMainState } from "@/store/modules/ebn_state";
// import ebnCharacterControllers from "@/webgl/entities/ebn/Characters";
import Vue from "vue";
import Component from "vue-class-component";
import EBNArView from "../EBNArView/EBNArView.vue";
import EBNIntro from "../EBNIntro/EBNIntro.vue";
import EBNCharacterCarousel from "../EBNCharacterCarousel/EBNCharacterCarousel.vue";
import EBNDisclaimerButton from "../EBNDisclaimerButton/EBNDisclaimerButton.vue";
import EBNCarFeaturesPanel from "../EBNCarFeatures/EBNCarFeaturesPanel.vue";
import EBNDisclaimerPanel from "../EBNDisclaimerPanel/EBNDisclaimerPanel.vue";
import EBNFinalCtasPanel, {
  Link,
} from "../EBNFinalCtasPanel/EBNFinalCtasPanel.vue";
import EBNFooter from "../EBNFooter/EBNFooter.vue";
import EBNHintCard, { EBNHintType } from "../EBNHintCard/EBNHintCard.vue";
import EBNHowToScreen from "../EBNHowToScreen/EBNHowToScreen.vue";
import EBNInteractionViews from "../EBNInteractionViews/EBNInteractionViews.vue";
import EBNLoading from "../EBNLoading/EBNLoading.vue";
import EBNMenuPage from "../EBNMenuPage/EBNMenuPage.vue";
import EBNNavBar from "../EBNNavBar/EBNNavBar.vue";
import EBNSharePanel from "../EBNSharePanel/EBNSharePanel.vue";
import EBNSubtitleBubble from "../EBNSubtitleBubble/EBNSubtitleBubble.vue";
import EbnPopins from "@/components/EBNPopin/EBNPopins.vue";
import EBNBackToCarousel from "@/components/EBNBackToCarousel/EBNBackToCarousel.vue";
import EBNLogo from "@/components/EBNLogo/EBNLogo.vue";
import { Watch } from "vue-property-decorator";
import Delay from "@/core/Delay";
import { safari } from '@/core/Browser';
import fps from "@/store/modules/fps";

@Component({
  components: {
    EBNIntro,
    EBNNavBar,
    EBNLogo,
    EBNArView,
    EBNFooter,
    EBNSubtitleBubble,
    EBNHowToScreen,
    EBNFinalCtasPanel,
    EBNSharePanel,
    EBNMenuPage,
    EBNHintCard,
    EBNInteractionViews,
    EBNCharacterCarousel,
    EBNDisclaimerButton,
    EBNDisclaimerPanel,
    EBNCarFeaturesPanel,
    EBNLoading,
    EbnPopins,
    EBNBackToCarousel,
  },
})
export default class EBNMainPage extends Vue {

  switch: boolean = false;
  loaded: boolean = false;

  async mounted() {
    window.addEventListener('resize', () => this.resize());
  }

  resize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  get introDone() {
    return ebn_state.introDone;
  }

  get showMenu() {
    return (
      this.introDone &&
      ebn_state.characterState !== "loading" &&
      ebn_state.characterState !== "endloading" &&
      ebn_state.characterState !== "tap_to_place"
    );
  }

  get isLoading() {
    return (
      ebn_state.characterState === "loading" ||
      ebn_state.characterState === "endloading"
    );
  }

  @Watch("isLoading")
  async checkLoading(n: boolean) {
    if(n) {
      this.loaded = n;
    } else {
      if(safari && (Math.round(fps.value) < 30)) await Delay(3000);
      this.loaded = false;
    }
  }

  get charselMode() {
    return ebn_state.mainState === EBNMainState.CAROUSEL;
  }

  get notCarousel() {
    return ebn_state.mainState === EBNMainState.AR;
  }

  @Watch("switchCharacters")
  wSwitchCharacters(n: boolean) {
    this.switch = n;
  }

  get activeSwitch() {
    return this.switch
  }

  get switchCharacters() {
    return ebn_state.switchCharacters;
  }

  get schemeClass() {
    return primaryClass(ebn_state.currentCharacter);
  }

  get sharePanelOpened() {
    return ebn_state.sharePanelOpened;
  }

  get menuPanelOpened() {
    return ebn_state.menuPanelOpened;
  }

  get disclaimerOpened() {
    return ebn_state.disclaimerOpened;
  }

  get currentDisclaimerData(): EBNDisclaimerData {
    return ebn_state.currentDisclaimerData;
  }

  get showDisclaimerBtn(): boolean {
    const at = ebn_state.animationCurrentTime;
    let inrange;

    if(ebn_state.currentCharacter == "constance") {
      inrange = at > 2 && at < 8 && ebn_state.characterState === "speech";
    }
    if(ebn_state.currentCharacter == "joel") {
      inrange = at > 0 && at < 4 && ebn_state.currentAnim == "speech_2";
    }

    return (
      this.currentDisclaimerData !== null
      && inrange
    );
  }

  get links(): Link[] {
    return [
      {
        url: this.$t(ebn_state.currentCharacter + ".share.url") as string,
      },
    ];
  }

  get hintType(): EBNHintType {
    switch (ebn_state.characterState) {
      case "tap_to_place":
        return EBNHintType.TAP_TO_PLACE;
    }
    return null;
  }

  get showFooter(): boolean {
    if (ebn_state.characterState === "tap_to_place") return false;
    return true;
  }

  get showFinalCta(): boolean {
    return (
      ebn_state.characterState === "disposed"
      && !ebn_state.carFeaturesOpen
    );
  }

  get currentSubtitles() {
    return ebn_state.currentSubtitles;
  }

  get subtitlesEnabled() {
    return ebn_state.subtitlesEnabled;
  }

  get showHowToScreen() {
    return ebn_state.characterState === "tuto";
  }
}
