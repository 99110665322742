import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import AnimationData, { AnimationPlayer } from "./AnimationPlayer";
import { CharacterSequence } from "./CharacterAudioLib";
import ebn_state from '../../../store/modules/ebn_state';
import ebn_market from '@/store/modules/ebn_market';
import { EbnAnimationIdentifier } from "@/store/models/EbnAnimationSequence";





export default class CharacterAnimator {

  player : AnimationPlayer

  constructor( private gltf: Gltf ){
    this.player = new AnimationPlayer()
    this.player.init( gltf )
  }

  playAnimation( seq: CharacterSequence ){
    let name = seq.animId
    let anim = this.gltf.getElementByName( GltfTypes.ANIMATION, name )
    console.log("before", name)

    if(ebn_market.currentMarketEBN == "mx" && ebn_state.currentCharacter == "zoe") {
      switch(name) {
        case "trigger_2":
          name = EbnAnimationIdentifier.TRIGGER_3;
          anim = this.gltf.getElementByName( GltfTypes.ANIMATION, "trigger_3" );
          break;
        case "speech_2":
          name = EbnAnimationIdentifier.SPEECH_3;
          anim = this.gltf.getElementByName( GltfTypes.ANIMATION, "speech_3" );
          break;
        case "success_2":
          name = EbnAnimationIdentifier.SUCCESS_3;
          anim = this.gltf.getElementByName( GltfTypes.ANIMATION, "success_3" );
          break;
        case "speech_3":
          name = EbnAnimationIdentifier.SPEECH_4;
          anim = this.gltf.getElementByName( GltfTypes.ANIMATION, "speech_4" );
          break;
        case "success_3":
          name = EbnAnimationIdentifier.SUCCESS_4;
          anim = this.gltf.getElementByName( GltfTypes.ANIMATION, "success_4" );
          break;
        case "trigger_3":
          name = EbnAnimationIdentifier.TRIGGER_4;
          anim = this.gltf.getElementByName( GltfTypes.ANIMATION, "trigger_4" );
          break;        
      }
    }

    ebn_state.setCurrentAnim(name);
    console.log(name);
    
    if( anim ){
      const animData = new AnimationData( anim, seq )
      this.player.playAnimation( animData )
    } else {
      if(name == "motivational") {
        anim = this.gltf.getElementByName( GltfTypes.ANIMATION, "waiting_1" );
        const animData = new AnimationData( anim, seq )
        this.player.playAnimation( animData )
      } else {
        console.error( `Animation "${name}" not found`)
      }
    }
    
  }
  
  update(){
    this.player.update()
  }

  dispose(){
    this.player.dispose()
    this.player = null
    this.gltf = null
  }

}