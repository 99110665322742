import Animation from "@/webgl/lib/nanogl-gltf/lib/elements/Animation"
import AnimationChannel from "@/webgl/lib/nanogl-gltf/lib/elements/AnimationChannel"
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node"
import Gltf2 from "@/webgl/lib/nanogl-gltf/lib/types/Gltf2"
import { TypedArray } from "@/webgl/lib/nanogl-gltf/lib/types/TypedArray"
import { vec3, quat } from "gl-matrix"

function lerpArray(out: Float32Array, a: Float32Array, b: Float32Array, mix: number) {
  const imix = 1.0 - mix
  for (let i = 0; i < out.length; i++) {
    out[i] = imix * a[i] + mix * b[i]
  }
}

export function findChannel(channels: AnimationChannel[], node: Node, path: Gltf2.AnimationChannelTargetPath): number {
  return channels.findIndex(c => c.node === node && c.path === path)
}


export function mixChannels(out: TypedArray, a: TypedArray, b: TypedArray, mix: number, path: Gltf2.AnimationChannelTargetPath): void {
  switch (path) {

    case Gltf2.AnimationChannelTargetPath.TRANSLATION:
      vec3.lerp(out as vec3, a as vec3, b as vec3, mix)
      break

    case Gltf2.AnimationChannelTargetPath.ROTATION:
      quat.slerp(out as quat, a as quat, b as quat, mix)
      break

    case Gltf2.AnimationChannelTargetPath.SCALE:
      vec3.lerp(out as vec3, a as vec3, b as vec3, mix)
      break

    case Gltf2.AnimationChannelTargetPath.WEIGHTS:
      lerpArray(out as Float32Array, a as Float32Array, b as Float32Array, mix)
      break
    // default: 
    //     throw new Error('unsupported path ' + path);
  }
}

export function applyChannels(value: TypedArray, node: Node, path: Gltf2.AnimationChannelTargetPath): void {
  switch (path) {

    case Gltf2.AnimationChannelTargetPath.TRANSLATION:
      node.position.set(value)
      node.invalidate()
      break

    case Gltf2.AnimationChannelTargetPath.ROTATION:
      node.rotation.set(value)
      node.invalidate()
      break

    case Gltf2.AnimationChannelTargetPath.SCALE:
      node.scale.set(value)
      node.invalidate()
      break

    case Gltf2.AnimationChannelTargetPath.WEIGHTS:
      node.weights.set(value)
      break
    // default: 
    //     throw new Error('unsupported path ' + path);
  }
}



export function mixAnimation(animA: Animation, animB: Animation, mix: number, timeA: number, timeB: number, normalise = false) {
  // if(normalise) {
  //     const minAnim = Math.max(animA.duration, animB.duration)
  //     timeA = timeA / animA.duration * minAnim
  //     timeB = timeB / animB.duration * minAnim
  // }
  const ta = timeA % animA.duration
  const tb = timeB % animB.duration

  const bChans = Array.from(animB.channels)

  for (let i = 0; i < animA.channels.length; i++) {

    const channelA = animA.getChannel(i)

    const ci = findChannel(bChans, channelA.node, channelA.path)
    const channelB = bChans[ci]
    if (channelB) {
      bChans.splice(bChans.indexOf(channelB))
    }


    if (!channelA || !channelB || !channelA.valueHolder || !channelB.valueHolder) continue

    channelA.evaluator.evaluate(channelA.valueHolder, ta);
    channelB.evaluator.evaluate(channelB.valueHolder, tb);


    switch (channelA.path) {
      case Gltf2.AnimationChannelTargetPath.TRANSLATION:
        vec3.lerp(channelA.node.position, channelA.valueHolder as vec3, channelB.valueHolder as vec3, mix)
        channelA.node.invalidate()
        break
      case Gltf2.AnimationChannelTargetPath.ROTATION:
        quat.slerp(channelA.node.rotation, channelA.valueHolder as quat, channelB.valueHolder as quat, mix)
        channelA.node.invalidate()
        break
      case Gltf2.AnimationChannelTargetPath.SCALE:
        vec3.lerp(channelA.node.scale, channelA.valueHolder as vec3, channelB.valueHolder as vec3, mix)
        channelA.node.invalidate()
        break
      case Gltf2.AnimationChannelTargetPath.WEIGHTS:
        if (channelA.node.weights) lerpArray(channelA.node.weights, channelA.valueHolder as Float32Array, channelB.valueHolder as Float32Array, mix)
        break
      // default: 
      //     throw new Error('unsupported path ' + path);
    }

  }


  // apply orphan bChans

}
