








































import gsap, { Power2 } from "gsap";
import SchemeColoredMixin from "@/mixins/SchemeColored";
import ebn_state, { EBNMainState } from "@/store/modules/ebn_state";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import car_features from "@/store/datas/car_features.json";
import car_features_mx from "@/store/datas/car_features_mx.json";
import { TranslateResult } from 'vue-i18n';
import Delay from '@/core/Delay';
import TrackingEbn from "@/core/TrackingEbn";
import ebn_market from "@/store/modules/ebn_market";
import Paths from '@/core/Paths';

@Component({
  mixins: [SchemeColoredMixin],
})

export default class EBNCarFeatures extends Vue {
  timeline: gsap.core.Timeline;
  idx: number;

  get menuOpen() {
    return (
      !ebn_state.menuPanelOpened &&
      ebn_state.mainState !== EBNMainState.CAROUSEL &&
      ebn_state.carFeaturesOpen
    );
  }

  get carFeaturesOpen() {
    return ebn_state.carFeaturesOpen
  }

  get currentName() {
    return ebn_state.currentNameFeature;
  }

  get feature() {
    return ebn_state.currentFeature;
  }

  resolveSrc(p: string) {
    return Paths.resolve(p);
  }

  get carFeatures() {
    if(ebn_market.currentMarketEBN == "mx") {
      return car_features_mx
    }

    return car_features
  }

  get currentFeature() {
    if(this.currentName) {
      const id = this.currentName.replace(/ /g,'_');
      const feature = this.carFeatures.filter(v => v.id == id)[0];
      this.idx = feature.index;

      ebn_state.setCurrentFeature(feature);
      return feature;
    } else {
      const character = ebn_state.currentCharacter;
      const feature = this.carFeatures.filter(v => v.character == character)[0];
      this.idx = feature.index;

      ebn_state.setCurrentFeature(feature);
      return feature;
    }
  }

  get title(): TranslateResult {
    return this.feature ? this.$t(`${this.feature.key}.title`) : ''
  }

  get description(): TranslateResult {
    return this.feature ? this.$t(`${this.feature.key}.description`) : ''
  }

  get legals(): TranslateResult {
    return this.feature && (this.$t(`${this.feature.key}.legals`) !== `${this.feature.key}.legals`) ? this.$t(`${this.feature.key}.legals`) : null;
  }

  get images(): string[] {
    return this.feature.medias != undefined ? this.feature.medias : [];
  }

  mounted() {
    this.currentFeature
  }

  beforeDestroy() {
    this.$el
      .getElementsByClassName("panel-wrapper")[0]
      .removeEventListener("scroll", this.scroll);
  }

  @Watch("carFeaturesOpen")
  async scrollBar(n: boolean) {
    if (n) {
      const scrollable = this.$el.getElementsByClassName(
        "scrollable"
      )[0] as HTMLElement;
      const scrollWrapper = this.$el.getElementsByClassName(
        "panel-wrapper"
      )[0] as HTMLElement;

      await Delay(250);
      const scrollMax: number = scrollable.offsetHeight - scrollWrapper.offsetHeight;
      const scrollContainer: number = scrollWrapper.offsetHeight;
      (this.$refs.scrollVal as HTMLElement).style.height = `${scrollContainer - scrollMax}px`;
      
      if ((scrollWrapper.offsetHeight / scrollable.offsetHeight >= 1))
        (this.$refs.scrollVal as HTMLElement).style.visibility = "hidden";
      else (this.$refs.scrollVal as HTMLElement).style.visibility = "visible";

      this.$el
        .getElementsByClassName("panel-wrapper")[0]
        .addEventListener("scroll", this.scroll);
    } else {
      this.$el
        .getElementsByClassName("panel-wrapper")[0]
        .removeEventListener("scroll", this.scroll);
    }
  }

  scroll() {
    const s = this.$el.getElementsByClassName(
      "panel-wrapper"
    )[0] as HTMLElement;

    const sT: number = s.scrollTop;
    gsap.set(
      this.$refs.scrollVal,
      { y: `${sT}px` }
    );
  }

  goPrevFeature() {
    const character = ebn_state.currentCharacter;
    const allFeatures = this.carFeatures.filter(v => v.character == character).length - 1;

    this.scrollBar(true);
    TrackingEbn.prevFeature(ebn_state.currentCharacter);
    
    if(this.idx == 0) {
      this.idx = allFeatures
    } else {
      this.idx--;
    }

    const feature = this.carFeatures.filter(v => v.character == character)[this.idx];
    ebn_state.setCurrentFeature(feature);
  }

  goNextFeature() {
    const character = ebn_state.currentCharacter;
    const allFeatures = this.carFeatures.filter(v => v.character == character).length - 1;

    this.scrollBar(true);
    TrackingEbn.nextFeature(ebn_state.currentCharacter);

    if(allFeatures > this.idx) {
      this.idx++;
    } else {
      this.idx = 0;
    }

    const feature = this.carFeatures.filter(v => v.character == character)[this.idx];
    ebn_state.setCurrentFeature(feature);
  }

  async closePanel() {
    ebn_state.closeCarFeatures();
    //await Delay(1000);
  }

}
