<template>
  <div class="ebn-popins">
    <EbnPopin :popinVisible="showFpsWarning">
      <h2>{{ $t("errors.fps_title") }}</h2>
      <p>{{ $t("errors.fps_text") }}</p>
      <EBNButton @click="closeWarning">{{
        $t("errors.fps_stay_cta")
      }}</EBNButton>
      <EBNButton :light="true" @click="gotoChevrolet">{{
        $t("errors.fps_bail_cta")
      }}</EBNButton>
    </EbnPopin>
    <EbnPopin :popinVisible="false">
      <h2>{{ $t("errors.lost_title") }}</h2>
      <p>{{ $t("errors.lost_text") }}</p>
      <EBNButton>{{ $t("errors.lost_cta") }}</EBNButton>
    </EbnPopin>
  </div>
</template>

<script>
import EbnPopin from "@/components/EBNPopin/EBNPopin.vue";
import TrackingEbn from "@/core/TrackingEbn";
import ebn_state from "@/store/modules/ebn_state";
import Vue from "vue";
import Component from "vue-class-component";
import EBNButton from "../EBNButton/EBNButton.vue";
@Component({
  components: {
    EbnPopin,
    EBNButton,
    // EVHotspots
  },
})
export default class EBNPopins extends Vue {
  warningShown = false;

  get showFpsWarning() {
    const isWarning = ebn_state.isFPSWarning && !this.warningShown;
    if (isWarning) TrackingEbn.fpsPopin();
    return isWarning;
  }

  closeWarning() {
    this.warningShown = true;
    TrackingEbn.errorButtons(ebn_state.currentCharacter, true);
    ebn_state.setIsFPSWarning(false);
  }

  gotoChevrolet() {
    TrackingEbn.errorButtons(ebn_state.currentCharacter, false);
    const url = `${this.$t("errors.fps_bail_href").toString()}?evar25=ch_bolt-euv-friends_err-modal`;
    window.open(url, "_blank");
  }
}
</script>

<style lang="stylus">
.ebn-popins
  z-index: 21;
  position relative
      transform: translateY(calc(50vh - 50%));
</style>
