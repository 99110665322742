


















import ebn_state from "@/store/modules/ebn_state";
import { clamp01 } from "@/webgl/math";
import gsap, { Quart } from "gsap";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class CarouselTuto extends Vue {
  @Prop({ type: Number, required: true }) position!: number;
  @Prop({ type: Boolean, required: true }) isDragged!: boolean;

  get progress() {
    return Math.abs(this.position);
  }

  get flipflop() {
    return this.position > 0.01;
  }

  get spacerwidth() {
    return this.progress * 100;
  }

  get cursorOpacity() {
    return clamp01(3 - 4 * this.progress);
  }

  mounted() {
    const dots = Array.from(this.$el.querySelectorAll(".dots > *")).reverse();
    gsap.fromTo(
      dots,
      { scale: 0 },
      { duration: 0.3, stagger: 0.1, ease: Quart.easeOut, scale: 1, delay: 0.3 }
    );

    const cursor = this.$el.querySelector(".cursor");
    gsap.fromTo(
      cursor,
      { scale: 0 },
      {
        duration: 0.2,
        stagger: 0.025,
        ease: Quart.easeOut,
        scale: 1,
        delay: 0.4,
        onComplete: () => cursor.classList.add("show"),
      }
    );

    const text = this.$el.querySelector(".text");
    gsap.fromTo(
      text,
      { y: 30, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        ease: Quart.easeOut,
        delay: 0.6,
      }
    );
  }
}
