import { CharacterConfig, EbnCharacter } from "../models/EbnCharacters"
import ebn_market from '../modules/ebn_market'

export type EbnCharacterState = {
  completedUsps: boolean[]
}

let ebn_character_configs: CharacterConfig[];

if(ebn_market.currentMarketEBN == "mx") {
  ebn_character_configs = [
    {
      id : 'zoe',
      numUsps: 3,
      numTriggers: 3,
      numFailure: 0,
      numSuccess: 3,
      numWaitings: 1
    },
    {
      id : 'constance',
      numUsps: 3,
      numTriggers: 3,
      numFailure: 3,
      numSuccess: 3,
      numWaitings: 3
    },
    {
      id : 'joel',
      numUsps: 3,
      numTriggers: 3,
      numFailure: 3,
      numSuccess: 3,
      numWaitings: 1
    },
    {
      id : 'robert',
      numUsps: 2,
      numTriggers: 2,
      numFailure: 2,
      numSuccess: 2,
      numWaitings: 1
    } 
  ]
} else {
  ebn_character_configs= [
    {
      id : 'zoe',
      numUsps: 4,
      numTriggers: 4,
      numFailure: 0,
      numSuccess: 4,
      numWaitings: 1
    },
    {
      id : 'constance',
      numUsps: 3,
      numTriggers: 3,
      numFailure: 3,
      numSuccess: 3,
      numWaitings: 3
    },
    {
      id : 'joel',
      numUsps: 3,
      numTriggers: 3,
      numFailure: 3,
      numSuccess: 3,
      numWaitings: 1
    },
    {
      id : 'robert',
      numUsps: 2,
      numTriggers: 2,
      numFailure: 2,
      numSuccess: 2,
      numWaitings: 1
    } 
  ]
}

export function getCharacterConfig( id:EbnCharacter ) : CharacterConfig {
  return ebn_character_configs.find( e=>e.id === id )
}

export function createEbnCharacterStates() : Record<EbnCharacter, EbnCharacterState> {
  const res : Record<string, EbnCharacterState>= {}
  for (const c of ebn_character_configs) {
    res[c.id] = {
      completedUsps: new Array(c.numUsps).fill(false)
    }
  }
  return res
}

export default ebn_character_configs