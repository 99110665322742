<template>
  <div class="ebn-logo" v-show="showLogo">
    <img src="@/assets/images/chevrolet-logo2x.png" />
  </div>
</template>

<script>
import Vue from "vue";
import ebn_state from "@/store/modules/ebn_state";
import Delay from "@/core/Delay";

export default class EBNLogo extends Vue {
  get showLogo() {
    return (
      ebn_state.videoDone !== false &&
      ebn_state.characterState !== "loading" &&
      ebn_state.characterState !== "endloading" &&
      ebn_state.characterState !== "tap_to_place"
    );
  }
}
</script>

<style lang="stylus">
.ebn-logo
  position absolute
  top 0
  left calc(50% - 18%)
  width 36%
  background #FAFAFA
  border-bottom-left-radius 16px
  border-bottom-right-radius 16px
  z-index 6
  transition opacity .5s

  img
    width 100%
    padding 16px 20px
</style>