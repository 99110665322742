















import SchemeColoredMixin from '@/mixins/SchemeColored';
import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';


@Component({
  mixins:[
    SchemeColoredMixin
  ]
})
export default class UIIconBtn extends Vue {

  @Prop({type:String, required:true}) icon!: string

  @Prop({type:Boolean, required:false}) disabled!: string

}

