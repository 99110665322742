
import srtParser, { Entry } from 'subtitles-parser'


function parseTimecode(code:string) {
  const [h, m, s, ms] = code.split(/:|,/g).map(v => parseInt(v))
  return h * 3600 + m * 60 + s + ms / 1000;
}

function entryToSub(e:Entry): Sub {

  const start = parseTimecode(e.startTime)
  const end = parseTimecode(e.endTime)
  return {
    start, end, text:e.text
  }
}


type Sub = {
  start:number
  end:number
  text:string
}

export default class Subtitles {

  list : Sub[]

  constructor( private path:string ){}

  async load(): Promise<void> {
    const srtContent = await fetch(this.path).then(r => r.text())
    this.list = srtParser.fromSrt(srtContent).map( entryToSub )
  }

  getTextAtTime( seconds:number): string | null {
    const l = this.list
    const t = seconds

    if( !l )return null
    
    for (let i = 0; i < l.length; i++) {
      const sub = l[i];
      if( t > sub.start && t < sub.end ) {
        return sub.text
      }  
    }
    
    return null
  }

}