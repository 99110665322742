




















import { EBNDisclaimerData } from "@/store/models/EBNDisclaimerData";
import ebn_state from "@/store/modules/ebn_state";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import gsap, { Expo } from "gsap";

@Component
export default class EBNDisclaimerPanel extends Vue {
  @Prop({ type: Object, required: true }) data!: EBNDisclaimerData;

  timeline: gsap.core.Timeline;

  // @Watch("data")
  // onTextChange(data: EBNDisclaimerData) {
  //   if (data !== null) {
  //     this.$nextTick(() => {
  //       this.buildTimeLine();
  //       this.timeline.invalidate().restart();
  //     });
  //   }
  // }

  onClose() {
    ebn_state.closeDisclaimer();
  }

  mounted() {
    this.buildTimeLine();
    this.timeline.invalidate().restart();
  }

  buildTimeLine() {
    const title = this.$el.querySelector(".title");
    const text = this.$el.querySelector(".text");
    const legals = this.$el.querySelector(".legals");
    const closebtn = this.$el.querySelector(".closebtn");
    this.timeline = gsap.timeline();
    this.timeline
      .fromTo(
        this.$el,
        {
          y: "100%",
        },
        {
          y: 0,
          ease: Expo.easeOut,
          duration: 0.75,
        }
      )
      .fromTo(
        closebtn,
        {
          scale: 0.3,
          opacity: 0,
          rotation: 90,
        },
        {
          scale: 1,
          opacity: 1,
          rotation: 0,
          ease: Expo.easeOut,
          duration: 0.75,
          delay: 0.2,
        },
        "<"
      )
      .fromTo(
        title,
        {
          y: 30,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          ease: Expo.easeOut,
          duration: 0.75,
          delay: 0.2,
        },
        "<"
      )
      .fromTo(
        text,
        {
          y: 30,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          ease: Expo.easeOut,
          duration: 0.75,
          delay: 0.22,
        },
        "<"
      )
      .fromTo(
        legals,
        {
          y: 30,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          ease: Expo.easeOut,
          duration: 0.75,
          delay: 0.24,
        },
        "<"
      );
  }
}
