












import TrackingEbn from "@/core/TrackingEbn";
import ebn_state from "@/store/modules/ebn_state";
import gsap, { Expo } from "gsap";
import { Quart, Sine } from "gsap/all";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CustomEase from "../EBNIntro/CustomEase";

const easeBack = CustomEase.create(
  "custom",
  "M0,0 C0.128,0.572 0.229,1.081 0.49,1.03 0.642,1 0.838,1 1,1 "
);

@Component
export default class EBNDisclaimerButton extends Vue {
  @Prop({ type: String, required: true }) text!: string;

  timeline: gsap.core.Timeline;

  onClick() {
    ebn_state.openDisclaimer();
    TrackingEbn.tapToLearn(ebn_state.currentCharacter, this.text);
  }

  buildTimeLine() {
    const wrapper = this.$el;
    const arrow = this.$el.querySelector(".arrow");
    const text = this.$el.querySelector("span");
    this.timeline = gsap.timeline({ paused: true });
    this.timeline
      .fromTo(
        wrapper,
        {
          scale: 0,
          rotation: 10,
        },
        {
          duration: 0.75,
          rotation: 0,
          scale: 1,
          ease: Expo.easeOut,
        }
      )
      .fromTo(
        arrow,
        {
          scale: 0.3,
          opacity: 0,
          rotation: 45,
        },
        {
          scale: 1,
          opacity: 1,
          rotation: 0,
          ease: Expo.easeOut,
          duration: 0.75,
          delay: 0.1,
        },
        "<"
      )
      .fromTo(
        text,
        {
          scale: 0,
        },
        {
          duration: 0.6,
          scale: 1,
          ease: easeBack,
        },
        "<"
      );
  }

  mounted() {
    this.buildTimeLine();
    this.timeline.invalidate().restart();
  }
}
