






























































import TrackingEbn from "@/core/TrackingEbn";
import ebn_state from "@/store/modules/ebn_state";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import EBNButton from "../EBNButton/EBNButton.vue";
import Delay from '../../core/Delay';
import { LocaleMessage } from 'vue-i18n';
import gsap, { Expo, Quart } from "gsap";
import CustomEase from "../EBNIntro/CustomEase";
import ebn_market from '../../store/modules/ebn_market';
import Paths from '../../core/Paths';

export type Link = {
  url: string;
};

@Component({
  components: {
    EBNButton,
  },
})
export default class EBNFinalCtasPanel extends Vue {
  elX: number = 0;
  sX: number = 0;
  iHeight: number = 0;
  iWidth: number = 0;
  moveX: number = 0;

  easeBack = CustomEase.create(
    "custom",
    "M0,0 C0.128,0.572 0.229,1.081 0.49,1.03 0.642,1 0.838,1 1,1 "
  );

  @Prop({ type: Array, required: true }) links!: Link[];

  get shareBtnText() {
    return this.$t(`finalpanel.share`);
  }

  get homeBtnText() {
    return this.$t(`finalpanel.home`);
  }

  get carURL(): string {
    if(ebn_market.currentMarketEBN == "mx") {
      return Paths.resolve(`assets/images/mx/intro-cover-car_mx.png`);
    }

    return Paths.resolve(`assets/images/intro-cover-car.png`);
  }

  get coverTitleFP() {
    return (this.$t("intro.title") as string)
      .split("<br />")[0]
      .split("")
      .map((c) => `<span>${c}</span>`)
      .join("");
  }

  get coverTitleEP() {
    return (this.$t("intro.title") as string)
      .split("<br />")[1]
      .split("")
      .map((c) => `<span>${c}</span>`)
      .join("");
  }

  mounted() {
    this.launchAnimation();
  }

  async launchAnimation() {
    this.animateMask(true);
    this.animateCoverIn(0.1);
  }

  onShareClick() {
    TrackingEbn.endLink("share");
    const title = this.$t(`share.title`).toString();
    const text = this.$t(`share.text`).toString();
    const url = this.$t(`share.url`).toString();

    if (navigator.share) {
      navigator.share({ url, title, text });
    }
  }

  onHomeClick() {
    TrackingEbn.endLink("home");
    ebn_state.setBackToCarousel(true);
  }

  tagBtn(label: string) {
    TrackingEbn.endLink(label);
  }

  animateMask(inOut: boolean) {
    this.iHeight = window.innerHeight;
    this.iWidth = window.innerWidth;
    gsap.to(this, {
      duration: 1.75,
      elX: inOut ? 1 : 2,
      sX: inOut ? 1 : 0,
      ease: Expo.easeInOut
    });
  }

  animateCoverIn(delay: number = 0) {
    this.animateCover(true, delay);
    this.animateTitle();
    this.animateButtons();
  }

  animateCoverOut(delay: number = 0) {
    this.animateCover(false, delay);
    gsap.to(this.$refs.bottom, { duration: 1, opacity: 0 });
  }

  animateCover(inOut: boolean, delay: number = 0) {
    const els: Array<HTMLElement> = Array.from(
      this.$el.getElementsByClassName("parallax")
    ) as Array<HTMLElement>;
    const coversParallax = [1.5, 3.8, 3.8, 3, 4.6, 4, 4.9];
    const coversRotation = [false, true, false, false, true, true, true];
    const coversEase = [
      Quart.easeInOut,
      Quart.easeInOut,
      this.easeBack,
      this.easeBack,
      Quart.easeInOut,
      Quart.easeInOut,
      Quart.easeInOut,
    ];
    const coversEaseOut = Quart.easeInOut;

    els.forEach((c: HTMLElement, i) => {
      const screenOut = coversParallax[i] * window.innerWidth;
      gsap.fromTo(
        c,
        {
          x: inOut ? screenOut : 0,
          rotation: coversRotation[i] ? (inOut ? 40 : 0) : 0,
        },
        {
          x: inOut ? 0 : -screenOut,
          rotation: inOut ? 0 : coversRotation[i] ? 40 : 0,
          duration: 2,
          ease: inOut ? coversEase[i] : coversEaseOut,
          delay,
        }
      );
    });
  }

  animateTitle() {
    gsap.fromTo(
      ".ebn-intro h1 span",
      { x: 150, rotation: 30 },
      {
        x: 0,
        rotation: 0,
        duration: 0.3,
        delay: 0.65,
        stagger: 0.035,
        ease: Quart.easeInOut,
      }
    );
  }

  animateButtons() {
    const buttons = this.$el.querySelector(".btn-container")

    gsap.to(
      buttons,
      {
        opacity: 1,
        duration: 1,
        delay: 0.75,
        ease: Quart.easeInOut,
      }
    );
  }
}
