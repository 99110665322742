























































import Vue from "vue";
import Component from "vue-class-component";
import TrackingEbn from "@/core/TrackingEbn";
import ChevronLeft from "!vue-svg-loader!@/assets/icons/chevron-left.svg";
import ebn_state from "@/store/modules/ebn_state";
import ebn_market from '@/store/modules/ebn_market';

@Component({
  components: {
    ChevronLeft,
  },
})
export default class EBNMenuPage extends Vue {
  get mx(): boolean {
    return ebn_market.currentMarketEBN == "mx";
  }

  changeLanguage(event: any) {
    event.targetDefault()
    const lang = event.target.dataset.value.replace(`/${ebn_market.currentMarketEBN}/`, '').split(/\s/).join('');
    
    (this.$refs.select as HTMLElement).classList.toggle("open");

    if (this.$i18n.locale == lang) return;

    TrackingEbn.changeLanguage();

    window.location.href = event.target.dataset.value;
    // this.$i18n.locale = lang;
  }

  tagButton(alt: string) {
    TrackingEbn.menuButton(alt);
  }
}
