import Paths from "@/core/Paths";
import { EbnCharacter } from "@/store/models/EbnCharacters";
import ebn_state from "@/store/modules/ebn_state";
import Masks from "@/webgl/gl/Masks";
import Material from "@/webgl/lib/nanogl-gltf/lib/elements/Material";
import Scene from "@/webgl/Scene";
import Time from "@/webgl/Time";
import { quat, vec3 } from "gl-matrix";
import Node from "nanogl-node";
import { StandardPass } from "nanogl-pbr/StandardPass";
import GltfModule from "../GltfModule";
import CharacterAnimator from "./CharacterAnimator";
import ebnCharacterControllers from "./Characters";
import { ArStateValue } from '@/store/xstate/ebn_arstate'
import Delay from "@/core/Delay";
import ebn_market from '@/store/modules/ebn_market';


const Q_ID = quat.create()
const Q = quat.create()
const V3A = vec3.create()


export default class CharacterModel {

  
  gltfModule: GltfModule;
  root: Node;
  animator : CharacterAnimator
  introStarted:boolean = false

  constructor( private charid : EbnCharacter, private scene: Scene ){
    this.root = new Node()
    if(ebn_market.currentMarketEBN == "mx" && charid == "constance") {
      this.gltfModule = new GltfModule( scene, 
        Paths.resolve( `assets/characters/${charid}/${charid}_mx-resize.glb`),
        ebnCharacterControllers.iblMngr
      )
    } else {
      this.gltfModule = new GltfModule( scene, 
        Paths.resolve( `assets/characters/${charid}/${charid}-resize.glb`), 
        ebnCharacterControllers.iblMngr
      )
    }
    this.gltfModule.zsorting = true
    this.introStarted = false
  }

  async load() : Promise<void> {
    await this.gltfModule.load()

    this.setupMaterials()

    const a = this.gltfModule.gltf.animations.map( a=>[a.name, a.duration.toPrecision(3)] )
    //console.log( this.charid )
    //console.table( a )

    this.scene.root.add( this.root )
    this.root.add( this.gltfModule.gltf.root )

    this.animator = new CharacterAnimator( this.gltfModule.gltf )
  }

  
  preRender() {

    if(!this.introStarted) {
      if(ebn_state.characterState === "intro") this.waitAndStartRender()
      return
    }
    
    this.getBillboardRotation( Q )
    quat.slerp( this.root.rotation, this.root.rotation, Q, Math.min( 1, Time.dt*2.5 ) )

    this.root.invalidate()
  }

  rttRender(){
    if(!this.introStarted) return
    this.gltfModule.rttRender()
  }

  getBillboardRotation( out: quat ){
    const camPos = this.scene.camera._wposition as vec3
    const pos = this.root._wposition as vec3
    vec3.subtract( V3A, camPos, pos )
    const angle = -Math.atan2( V3A[2], V3A[0]) + Math.PI/2
    quat.rotateY( out, Q_ID, angle )
  }
  
  render(){
    this.animator.update()
    if(!this.introStarted) return
    this.gltfModule.render()
  }

  async waitAndStartRender() {
    await Delay(500)
    this.introStarted = true
  }



  // setup material blending based on name
  //
  setupMaterials() {

    const mats = this.gltfModule.gltf.materials
    const gl = this.scene.gl

    for (const m of mats) {

      if( m.name.includes('additive')){
        
        const pass = (m as Material).materialPass as StandardPass
        pass.mask = Masks.BLENDED
        pass.glconfig
          .enableBlend()
          .blendFunc(gl.ONE, gl.ONE)

      }

      if( m.name.includes('multiply')){

        const pass = (m as Material).materialPass as StandardPass
        pass.mask = Masks.BLENDED
        pass.glconfig
          .enableBlend()
          .blendFunc(gl.ZERO, gl.SRC_COLOR)

      }

      if( m.name.includes('doubleside')){
        const pass = (m as Material).materialPass as StandardPass
        pass.glconfig
        .enableCullface(false)

      }
    }
  }

  dispose() {
    this.scene.root.remove( this.root )
    this.animator?.dispose()
    this.gltfModule?.dispose()
    this.animator = null
    this.gltfModule = null
  }

}