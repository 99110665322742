














import Paths from '@/core/Paths';
import { EbnCharacter } from '@/store/models/EbnCharacters';
import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class EBNTriggerCounter extends Vue {

  @Prop({type:Number, required:true}) total!: number
  @Prop({type:Number, required:true}) count!: number

  @Prop({type:String, required:true}) character!: EbnCharacter

  get onSrc(){
    return Paths.resolve(`assets/images/tdot_${this.character}_on.png`)
  }

  get offSrc(){
    return Paths.resolve(`assets/images/tdot_${this.character}_off.png`)
  }

}

