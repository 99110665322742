






























import TrackingEbn from "@/core/TrackingEbn";
import AR8thView from "@/webgl/ar/AR8thView";
import ebnCharacterControllers from "@/webgl/entities/ebn/Characters";
import { clamp, clamp01 } from "@/webgl/math";
import { mat4Xaxis, mat4Yaxis, mat4Zaxis } from "@/webgl/math/mat4-axis";
import browserDetect from "browser-detect";
import { vec3 } from "gl-matrix";
import Vue from "vue";
import Component from "vue-class-component";

/////////////
////////////////////////////////////
//////////

function unbalanceWavegen(t: number): number {
  const harmonics = [1.234, 4.543, 6.754];
  let wave = 0;
  for (let i = 0; i < harmonics.length; i++) {
    wave += Math.sin(t * harmonics[i]);
  }
  wave /= harmonics.length;
  return Math.pow(wave, 1);
}

function now() {
  return performance.now() / 1000;
}

const XAXIS = vec3.create();
const YAXIS = vec3.create();
const ZAXIS = vec3.create();
const NORM_X = vec3.create();
const UP = vec3.fromValues(0, 1, 0);

function getCameraTilt(): number {
  const mat = AR8thView.cameraMatrix;
  mat4Xaxis(XAXIS, mat);
  mat4Yaxis(YAXIS, mat);
  mat4Zaxis(ZAXIS, mat);

  vec3.cross(NORM_X, ZAXIS, UP);
  vec3.normalize(NORM_X, NORM_X);

  return vec3.dot(YAXIS, NORM_X);
}

@Component
export default class JoelInteractionView extends Vue {
  public progress = 0;
  public isComplete = false;

  public balancePos = 0;
  public rebalanceForce = 0;
  public rebalanceDebugForce = 0;

  private uinterval: number;
  startTime: number;
  lastUnbalancedTime: number;

  get dotX(): number {
    return this.balancePos * 50;
  }

  get pictoScale(): number {
    return 1.0 + this.progress * 0.3;
  }

  get fillOpacity(): number {
    return 0.2 + this.progress * 0.2;
  }

  get dotsScale(): number {
    return 1.0 + this.progress * 0.3;
  }

  mounted() {
    this.startTime = now();
    this.uinterval = window.setInterval(() => {
      this.update();
    }, 50);

/////////////////
//////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////
//////////////////////////////////////////////////////////
/////////
/////
//////////////
  }

  destroyed() {
    window.clearInterval(this.uinterval);
  }

  update() {
    const _now = now();
    const tilt = getCameraTilt();

    this.rebalanceForce = -tilt;

/////////////////
//////////////////////////////
/////////////////////////////////////////////////////
/////
//////////////

    const dt = _now - this.startTime;
    const ubduration = 8;
    const ubdecay = clamp01(3 * (1 - dt / ubduration));
    const ubattack = clamp01(-1 + dt / 2);
    const ubstrength = ubattack * ubdecay;

    const unbalanceForce = unbalanceWavegen(dt * 0.5) * ubstrength;
    this.balancePos += unbalanceForce * 0.15 + this.rebalanceForce * 0.25;
    this.balancePos = clamp(this.balancePos, -2, 2);

    if (Math.abs(this.balancePos) > 1.99 && dt > 5) {
      this.onFailure();
    }

    if (Math.abs(this.balancePos) > 0.3) {
      this.lastUnbalancedTime = _now;
    }

    const balancedTime = _now - this.lastUnbalancedTime;
    this.progress = clamp01(balancedTime / 1);

    if (dt > 2 && this.progress >= 1) {
      // win
      this.balancePos = 0;
      this.isComplete = true;
      window.clearInterval(this.uinterval);
      window.setTimeout(() => {
        this.onSuccess();
      }, 1000);
    }
  }

  onSuccess() {
    TrackingEbn.balance();
    ebnCharacterControllers.markAsSuccess();
  }

  onFailure() {
    ebnCharacterControllers.markAsFailure();
  }
}
