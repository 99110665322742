import Deferred from "@/core/Deferred";
import Paths from "@/core/Paths";
import Subtitles from "@/core/subtitles";
import { EbnAnimationIdentifier, EbnAnimationMultiSequence, EbnAnimationSequence, EbnAnimationSequence_getAnimationAt, isAnimationLoop } from "@/store/models/EbnAnimationSequence";
import { CharacterConfig, EbnCharacter } from "@/store/models/EbnCharacters";
import { Howl } from "howler";
import ebn_market from '@/store/modules/ebn_market';
import ebn_state from "@/store/modules/ebn_state";

let locale = "en"

function loadHowl( sound:Howl ) : Promise<void> {
  return new Promise( (resolve)=>{
    sound.once('load', resolve);
    sound.load()
  })
}

export class CharacterSequence {
  
  audio : Howl
  subs : Subtitles
  
  _playing = false
  _playPromise : Promise<void> = null

  _lastTime = 0

  _endDefer:Deferred<void>
  
  
  constructor(readonly characterId: EbnCharacter, readonly animId: EbnAnimationIdentifier, readonly locale: string, readonly loop: boolean) {

    if(ebn_state.currentCharacter == "zoe" && ebn_market.currentMarketEBN == "mx") {
      switch(animId) {
        case "trigger_2":
          animId = EbnAnimationIdentifier.TRIGGER_3;
          break;
        case "speech_2":
          animId = EbnAnimationIdentifier.SPEECH_3;
          break;
        case "success_2":
          animId = EbnAnimationIdentifier.SUCCESS_3;
          break;
        case "speech_3":
          animId = EbnAnimationIdentifier.SPEECH_4;
          break;
        case "success_3":
          animId = EbnAnimationIdentifier.SUCCESS_4;
          break;
        case "trigger_3":
          animId = EbnAnimationIdentifier.TRIGGER_4;
          break;        
      }
    }

    const filename = Paths.resolve( `assets/characters/${characterId}/${animId}/${locale}` );
    const mxFilename = Paths.resolve( `assets/characters/${characterId}/${animId}/mex` );

    if(ebn_market.currentMarketEBN == "mx" && animId == "speech_3" && characterId == "constance") {
      this.audio = new Howl({
        src: `${mxFilename}.mp3`,
        loop: loop,
        preload: false
      })
    } else {
      this.audio = new Howl({
        src: `${filename}.mp3`,
        loop: loop,
        preload: false
      })
    }

    if(ebn_market.currentMarketEBN == "mx") {
      this.subs = new Subtitles( `${mxFilename}.srt` )
    } else {
      this.subs = new Subtitles( `${filename}.srt` )
    }

  }

  get currentTime() : number{
    const t = this.audio.seek() as number
    // prevent current time to return to 0 for non looping sequences
    if( !this.loop && t < this._lastTime ) {
      return this._lastTime
    }
    this._lastTime = t
    return t
  }

  get currentSubtitles() : string | null{
    return this.subs.getTextAtTime( this.currentTime )
  }

  get isPlaying(){
    return this._playing
  }

  load() : Promise<void>{
    return Promise.all([
      loadHowl( this.audio ),
      this.subs.load()
    ]).then()
  }


  play() : Promise<void> {
    if( !this._playing ){
      this._playing = true

      this._endDefer?.reject()
      this._endDefer = new Deferred()
        
      this.audio.play()
      if( !this.loop ){
        this.audio.once( 'end', ()=>{
          this._playing = false
          this._endDefer.resolve()
        })
      }
    }

    return this._endDefer.promise
  }
  
  stop() {
    this._playing = false
    this.audio.stop()
  }


  dispose(){
    this.stop()
    this.audio.unload()
    this._endDefer?.reject()
    this._endDefer = null
  }

}






export default class CharacterAudioLib {


  sequences: Map<EbnAnimationSequence, CharacterSequence> = new Map()

  _currentSequence : CharacterSequence = null

  constructor( private config : CharacterConfig ){

    this._createSequence( EbnAnimationIdentifier.INTRO)
    this._createSequence( EbnAnimationIdentifier.IDLE)
    this._createSequence( EbnAnimationIdentifier.MOTIVATION)
    this._createSequence( EbnAnimationIdentifier.OUTRO)
    

            
    for (let i = 0; i < config.numWaitings; i++) {
      this._createSequence( EbnAnimationSequence_getAnimationAt(EbnAnimationMultiSequence.WAITING, i))
    }
      
    for (let i = 0; i < config.numTriggers; i++) {
      this._createSequence( EbnAnimationSequence_getAnimationAt( EbnAnimationMultiSequence.TRIGGER, i))
    }
    
    for (let i = 0; i < config.numSuccess; i++) {
      this._createSequence( EbnAnimationSequence_getAnimationAt( EbnAnimationMultiSequence.SUCCESS, i))
    }
    
    for (let i = 0; i < config.numFailure; i++) {
      this._createSequence( EbnAnimationSequence_getAnimationAt( EbnAnimationMultiSequence.FAILURE, i))
    }
      
    for (let i = 0; i < config.numUsps; i++) {
      this._createSequence( EbnAnimationSequence_getAnimationAt( EbnAnimationMultiSequence.SPEECH, i))
    }

  }


  getSequence(id: EbnAnimationSequence): CharacterSequence {
    return this.sequences.get( id )
  }

  play(animId: EbnAnimationSequence): Promise<void> {

    this._currentSequence?.stop()
    this._currentSequence = this.sequences.get( animId )
    if( ! this._currentSequence ) throw `no audio "${animId}"`
    return this._currentSequence.play()
  } 

  
  load() : Promise<void>{
    const loads = Array.from( this.sequences.values() ).map( s=>s.load())
    
    return Promise.all([
      loads
    ]).then()
  } 
  
  stopCurrent() {
    this._currentSequence?.stop()
  }


  dispose() {
    this.sequences?.forEach( s=>s.dispose())
    this.sequences = null
  }

  private _createSequence( id: EbnAnimationIdentifier ){
    const s = new CharacterSequence( this.config.id, id, locale, isAnimationLoop(id) )
    this.sequences.set( id, s)
  }

}