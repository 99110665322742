import { EbnCharacter } from "@/store/models/EbnCharacters";
import CharacterController from "./CharacterController";
import RobertInteraction from "./RobertInteraction";
import ZoeInteraction from "./Zoeinteraction";


export interface ICharacterInteraction {
  renderBefore:boolean
  init( ctrl:CharacterController ):void
  enter():void
  leave():void
  dispose():void
  load():Promise<void>
  preRender(): void
  rttRender():void
  render():void
  
}


class NullInteraction implements ICharacterInteraction {
  public renderBefore:boolean = false
  init():void {0}
  enter(): void {0}
  leave(): void {0}
  dispose(): void {0}
  preRender(): void {0}
  rttRender(): void {0}
  render(): void {0}
  load(): Promise<void> {
    return Promise.resolve()
  }
}

export function CharacterInteractionFactory( id:EbnCharacter ): ICharacterInteraction {
  switch (id) {
    case "robert":
      return new RobertInteraction()
    case "zoe":
      return new ZoeInteraction()
  
    default:
      return new NullInteraction()
  }
}