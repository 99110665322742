




















import gsap, { Expo } from "gsap";
import SchemeColoredMixin from "@/mixins/SchemeColored";
import ebn_state, { EBNMainState } from "@/store/modules/ebn_state";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Delay from "@/core/Delay";
import TrackingEbn from "@/core/TrackingEbn";

export enum CarFeatures {
  EASY_HOME_CHARGING = "easy home charging",
  MY_CHEVY_APP = "my chevy app",
  GOODBYE_GAS = "goodbye gas",
  UPDATED_DESIGN = "updated design",
  PANORAMIC_SUNROOF = "panoramic sun-roof",
  AVAILABLE_VENTILATED_SEATS = "available ventilated seats",
  FALSE_CARGO_FLOOR = "false cargo floor",
  ONE_PEDAL_DRIVING = "one pedal driving",
  REGEN_ON_DEMAND = "regen on demand",
  SUPER_CRUISE = "super cruise",
  TAKE_CHARGE = "take charge",
  ZERO_TAILPIPE_EMISSIONS = "zero tailpipe emissions",
  LESS_MAINTENANCE = "less maintenance"
}

@Component({
  mixins: [SchemeColoredMixin],
})

export default class EBNCarFeatures extends Vue {
  @Prop({ type: String, required: false }) text: string;
  oldText: string = "";
  timeline: gsap.core.Timeline;

  get currentText(): string {
    return this.text !== null ? this.text : this.oldText;
  }

  get tuto(): boolean {
    return ebn_state.characterState === "tuto";
  }

  get showCarFeatures() {
    return (
      Object.values(CarFeatures).some(v => (this.currentText.toLowerCase().includes(v)))
      || this.currentText.toLowerCase().includes("color")
    );
  }

  get checkCarFeatures() {
    return Object.values(CarFeatures).some(v => (this.currentText.toLowerCase().includes(v)))
  }

  get customPulse() {
    return this.currentText.toLowerCase().includes("color");
  }

  get menuOpen(): boolean {
    return (
      !ebn_state.menuPanelOpened &&
      ebn_state.mainState !== EBNMainState.CAROUSEL && 
      !ebn_state.carFeaturesOpen &&
      ebn_state.characterState !== "disposed"
    );
  }

  @Watch("text")
  onTextChange(text: string) {
    if (text !== null) {
      this.oldText = text;
    }
  }

  @Watch("showCarFeatures")
  watchComplete(n: boolean) {
    if(n) {
      this.$nextTick(() => {
        this.pulseAnimation();
        this.timeline.invalidate().restart();
      })
    }
  }

  pulseAnimation() {
    const wrapper = this.$el;
    const round: HTMLElement = this.$el.querySelector(".ebn-car-features__detect")
    const bg: HTMLElement = this.$el.querySelector(".detect-bg")
    const border: HTMLElement = this.$el.querySelector(".detect-border")
    this.timeline = gsap.timeline({paused: true});

    this.timeline
      .fromTo(
        round,
        { scale: 1, opacity: 1 },
        {
          scale: 2.5,
          opacity: 0,
          duration: 2.5,
          ease: Expo.easeOut
        }
      )
      .fromTo(
        border,
        { borderWidth: 10 },
        {
          borderWidth: 1,
          duration: 1,
          ease: Expo.easeOut
        },
        "<"
      )
      .fromTo(
        wrapper,
        { scale: 1 },
        {
          scale: 1.2,
          duration: 1,
          ease: Expo.easeOut
        },
        "<"
      )
      .to(
        wrapper,
        {
          scale: 1,
          duration: .5,
          ease: Expo.easeOut
        },
        1
      )
      .fromTo(
        round,
        { scale: 1, opacity: 1 },
        {
          scale: 2.5,
          opacity: 0,
          duration: 2.5,
          ease: Expo.easeOut
        },
        "<"
      )
      .fromTo(
        border,
        { borderWidth: 10 },
        {
          borderWidth: 1,
          duration: 1,
          ease: Expo.easeOut
        },
        "<"
      )
      .fromTo(
        wrapper,
        { scale: 1 },
        {
          scale: 1.2,
          duration: 1,
          ease: Expo.easeOut
        },
        "<"
      )
      .to(
        wrapper,
        {
          scale: 1,
          duration: .5,
          ease: Expo.easeOut
        },
        2
      )
  }

  openFeature() {    
    ebn_state.openCarFeatures();
    TrackingEbn.openFeature(ebn_state.currentCharacter);

    if(!this.checkCarFeatures && this.customPulse) return;

    const feature: CarFeatures = Object.values(CarFeatures).filter(v => (this.currentText.toLowerCase().includes(v)))[0];
    ebn_state.setNameFeature(feature);
  }

}
