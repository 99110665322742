import { mix } from "@/webgl/math"

const numPoints = 32


export default class CircleRenderer {

  static renderProgress( p:number, left:boolean, vpw: number, vph: number,  normalized: boolean, intro:number = 1): string {

    if( p <-0.2 ) return ""
    
    //circle enter 12% viewport
    const over0 = .12
    // circle center is 42vw right out 
    const center0 = .42

    // x = right + .4vw
    const cx0 = vpw * (.5+center0)
    const cx1 = 100000

    const cy0 = vph/2
    const cy1 = 0


    let cx = mix(cx0, cx1, Math.pow(p,8))

    const rp = p;Math.pow( p, .5 )
    const radius = cx - (1-rp)*(0.5-over0)*vpw 

    if( left ) cx = -cx

    return this.render(
      cx,
      mix(cy0, cy1, p),
      radius * intro,
      vpw, vph,
      false,
      normalized
    )

  }


  static render(cx: number, cy: number, radius:number, vpw: number, vph: number, invert:boolean, normalized: boolean ): string {

    const dy = cy - vph/2

    let a1:number, a2:number;
    if( cx > 0 )
      a1 = Math.PI - Math.asin(dy/radius)
      else 
      a1 = Math.asin(dy/radius)


    const acx = Math.abs(cx)

    const dcx = acx - vpw/2
    const dcy = cy +  vph/2

    const distSq = dcx*dcx + dcy*dcy

    if( distSq > radius){
      // on side
      a2 = Math.acos(dcx/radius)
      
      if( cx > 0 ){
        a2 = Math.PI - a2
      }

    } else {
      // on top

      if( cx > 0 )
        a2 = Math.PI - Math.asin(dcy/radius)
      else 
        a2 = Math.asin(dcy/radius)
    }
    // debugger


    const points = []
    const dangle = (a2 - a1)/(numPoints-1)
    for (let i = 0; i < numPoints; i++) {
      const a = a1 + dangle*i
      const x = cx + radius * Math.cos(a) + vpw/2
      const y = cy - radius * Math.sin(a) + vph/2
      points.push([x, y])
    }
    
    



    const startx = (cx > 0)?vpw:0
    if( invert ){
      const tl = [0, 0]
      const tr = [vpw, 0]
      const bl = [0, vph]
      const br = [vpw, vph]

      if( cx > 0 ){
        points.push(tr, tl, bl)
      } else {
        points.push(tl, tr, br)
      }

    } else {
      points.unshift([startx, vph])
      points.push([startx, vph])
    }

    if( normalized ){
      for (const p of points) {
        p[0] /= vpw
        p[1] /= vph
      }
    }
    
    let d = `M ${startx} ${vph} `
    for (let i = 0; i < points.length; i++) {
      const char = i===0 ? 'M':'L'
      const [x,y] = points[i];
      if(isNaN(x) || isNaN(y)) continue
      d += `${char} ${x} ${y} `
      
    }
    d += `L ${startx} ${vph} `


    return d
    
  }
  
}