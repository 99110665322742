console.info("version: "+process.env.VUE_APP_VERSION   );
console.info("env: "    +process.env.VUE_APP_ENVCONFIG );

import '@/styles/index.styl'
import '@/components'
import '@/core/Audio'
import brightcovePlayerLoader from '@brightcove/player-loader';

import Vue     from 'vue'
import Ebn     from '@/views/Ebn.vue'
import store   from '@/store'
import i18n    from '@/locales/ebn'

import "@/store/xstate/ebn_arstate"

Vue.config.productionTip = false

Vue.prototype.$brightcovePlayerLoader = brightcovePlayerLoader

if(window.location.protocol != 'https:') {
  location.href = location.href.replace("http://", "https://");
}

new Vue({
  store,
  i18n,
  render: h => h(Ebn)
}).$mount('#app')