














import ebn_state, { EBNMainState } from "@/store/modules/ebn_state";
import ebnCharacterControllers from "@/webgl/entities/ebn/Characters";
import gsap, { Expo } from "gsap";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from 'vue-property-decorator';

@Component
export default class EBNBackToCarousel extends Vue {
  elX: number = 0;
  sX: number = 0;
  iHeight: number = 0;
  iWidth: number = 0;
  num: number = -1;
  mounted() {
    0;
  }

  @Watch("backToCarousel")
  openBackToCarousel(n: boolean) {
    if (n) {
      this.animateMask();
    }
  }

  get backToCarousel() {
    return ebn_state.backToCarousel;
  }

  animateMask() {
    let nb = 0;
    switch (ebn_state.currentCharacter) {
      case "joel":
        nb = 1;
        break;
      case "zoe":
        nb = 2;
        break;
      case "constance":
        nb = 3;
        break;
      case "robert":
        nb = 0;
        break;
    }
    this.num = nb;
    this.iHeight = window.innerHeight;
    this.iWidth = window.innerWidth;
    this.elX = 0;
    this.sX = 0;
    gsap.to(this, {
      duration: 1.75,
      elX: 1,
      sX: 1,
      ease: Expo.easeInOut,
      onComplete: () => this.finishRemove(),
    });
  }

  finishRemove() {
    ebn_state.setIsRevisit(this.num);
    ebnCharacterControllers.dispose();
    ebn_state.setMainState(EBNMainState.CAROUSEL);
  }
}
