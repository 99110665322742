// import ebn_state from "@/store/modules/ebn_state";
import audio_state from "@/store/modules/audio_state";
import { Howler } from "howler";
import { isIOS } from "@/core/Browser"

export default class VolumeMeter {
  volume:number
  analyser:AnalyserNode
  volumes:Uint8Array
  audioSource:MediaStreamAudioSourceNode
  async init() {
    this.volume = 0
    // Initialize
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      //if(!audio_state.isMuted && isIOS()) Howler.volume(0.05)
      const audioContext = Howler.ctx
      this.audioSource = audioContext.createMediaStreamSource(audioStream);
      this.analyser = audioContext.createAnalyser();
      this.analyser.fftSize = 1024;
      this.analyser.minDecibels = -127;
      this.analyser.maxDecibels = 0;
      this.analyser.smoothingTimeConstant = 0.8;
      this.audioSource.connect(this.analyser);
      // volumeCallback = () => {
        
      //   // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
      // };
    } catch(e) {
      console.error('Failed to initialize volume visualizer, simulating instead...', e);
      // Simulation
      //TODO remove in production!
      // let lastVolume = 50;
      // volumeCallback = () => {
      //   const volume = Math.min(Math.max(Math.random() * 100, 0.8 * lastVolume), 1.2 * lastVolume);
      //   lastVolume = volume;
      //   volumeVisualizer.style.setProperty('--volume', volume + '%');
      // };
    }
  }
  dispose() {
    this.audioSource?.disconnect()
    //if(!audio_state.isMuted && isIOS()) Howler.volume(0.2)
  }
  volumeTimeout() {
    const volumes = new Uint8Array(this.analyser.frequencyBinCount);
    this.analyser.getByteFrequencyData(volumes);
    let values = 0;

    const length = volumes.length;
    for (let i = 0; i < length; i++) {
      values += (volumes[i]);
    }

    const average = values / length;

    this.volume = average
  }
}
