import lib from "@/webgl/lib/nanogl-gltf/lib";
import Animation from "@/webgl/lib/nanogl-gltf/lib/elements/Animation";
import AnimationChannel from "@/webgl/lib/nanogl-gltf/lib/elements/AnimationChannel";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import Gltf2 from "@/webgl/lib/nanogl-gltf/lib/types/Gltf2";
import { clamp01 } from "@/webgl/math";
import Time from "@/webgl/Time";
import { vec3, quat } from "gl-matrix";
import { applyChannels, findChannel, mixChannels } from "./AnimationMixer";
import { CharacterSequence } from "./CharacterAudioLib";

const now = ()=>performance.now()



export interface IAnimationTimeProvider {
  time: number
}


export interface IMixProvider {
  mix: number
}


export interface IChannelOutput {
  value: Float32Array
  evaluate():void
}


export class ChannelOutput implements IChannelOutput {

  constructor( private channel: AnimationChannel, private time: IAnimationTimeProvider ){}

  get value(){
    return this.channel.valueHolder as Float32Array
  }

  evaluate(): void {
    this.channel.evaluate( this.time.time )
  }

}



export class CompositeOutput implements IChannelOutput {


  value: Float32Array

  constructor( private c1: IChannelOutput, private c2: IChannelOutput, private path : Gltf2.AnimationChannelTargetPath, private mixProvider:IMixProvider ){
    this.value = new Float32Array( c1.value.length )
  }

  evaluate(): void {
    this.c1.evaluate()
    this.c2.evaluate()
    mixChannels( this.value, this.c1.value, this.c2.value, this.mixProvider.mix, this.path )
  }

}


export class StaticOutput implements IChannelOutput {

  constructor( public value:Float32Array ){
  }

  evaluate(): void {
    0
  }

}



class ChannelEvaluator {

  constructor( private node :Node, private path: Gltf2.AnimationChannelTargetPath, private output: IChannelOutput ){}


  evaluate(){
    this.output.evaluate()
    applyChannels( this.output.value, this.node, this.path )
  }

}


class State {
  position = vec3.create()
  scale    = vec3.create()
  rotation = quat.create()
  weights? : Float32Array

  constructor( node:Node ){
    this.position.set( node.position)
    this.scale.set( node.scale)
    this.rotation.set( node.rotation)
    if( node.weights ){
      this.weights = new Float32Array( node.weights )
    }
  }
}


class DefaultStateProvider {



  states: Map<Node, State>

  init( nodes : Node[] ){

    this.states = new Map()
    for (let i = 0; i < nodes.length; i++) {
      const n = nodes[i];
      this.states.set(n, new State(n))
    }

  }

  getState( node:Node, path:Gltf2.AnimationChannelTargetPath ){
    switch (path) {
      case Gltf2.AnimationChannelTargetPath.TRANSLATION:
        return this.states.get(node).position
        break
        
        case Gltf2.AnimationChannelTargetPath.ROTATION:
        return this.states.get(node).rotation
        break
        
        case Gltf2.AnimationChannelTargetPath.SCALE:
        return this.states.get(node).scale
        break
        
        case Gltf2.AnimationChannelTargetPath.WEIGHTS:
        return this.states.get(node).weights
        break
      // default: 
      //     throw new Error('unsupported path ' + path);
    }
  }

  getPosition( node:Node, out:vec3 ){
    out.set( this.states.get(node).position )
  }

  getScale( node:Node, out:vec3 ){
    out.set( this.states.get(node).scale )
  }

  getRotation( node:Node, out:quat ){
    out.set( this.states.get(node).rotation )
  }

  getWeights( node:Node, out:Float32Array ){
    const w = this.states.get(node).weights
    if( w ) out.set( w )
  }

  dispose(){
    this.states = null;
  }

}




export class AnimationPlayer {
  outputs : ChannelEvaluator[] = []
  
  defaultStateProvider: DefaultStateProvider;
  previousAnimation: AnimationData
  currentAnimation: AnimationData
  
  mix : number = 0
  fadeDuration: number;
  
  dispose(){
    this.defaultStateProvider.dispose()
    this.defaultStateProvider = null
  }
  
  init(gltf: lib) {
    this.defaultStateProvider = new DefaultStateProvider()
    this.defaultStateProvider.init( gltf.nodes )
  }
  
  
  playAnimation( anim : AnimationData, fadeDuration = .25 ){
    this.currentAnimation?.detachSequence()
    this.previousAnimation = this.currentAnimation
    this.currentAnimation = anim
    this.mix = 1
    this.fadeDuration = fadeDuration
    this.createOutputs()
  }

  update( ){
    
    this.previousAnimation?.updateTime()
    this.currentAnimation?.updateTime()

    this.mix += Time.dt/this.fadeDuration
    this.mix = clamp01(this.mix)

    if( this.mix === 1 ){
      this.previousAnimation = null
      this.createOutputs()
    }

    for (let i = 0; i < this.outputs.length; i++) {
      const o = this.outputs[i];
      o.evaluate()
    }

  }


  createOutputs() {
    
    this.outputs.length = 0


    // previous anim exist, need to mix/crossfade previous with current
    // 
    if( this.previousAnimation ){
      
      const channelsA = this.previousAnimation.anim.channels
      const channelsB = this.currentAnimation.anim.channels
      
      
      const channelsBCopy = Array.from(channelsB)
      
      let oA: IChannelOutput
      let oB: IChannelOutput
      
      // list all chanel in previous and try to find same chanel in current, create composite from both
      //
      for (let i = 0; i < channelsA.length; i++) {
        
        
        const chanA = channelsA[i];
        const chanBindex = findChannel( channelsBCopy, chanA.node, chanA.path )
        oA = new ChannelOutput( chanA, this.previousAnimation )
        
        // if no channel exist in current, use the DefaultStateProvider
        
        if (chanBindex > -1) {
          const chanB = channelsBCopy[chanBindex]
          channelsBCopy.splice(chanBindex)
          oB = new ChannelOutput( chanB, this.currentAnimation )
        } else {
          oB = new StaticOutput( this.defaultStateProvider.getState( chanA.node, chanA.path ) )
        }
        
        this.outputs.push( new ChannelEvaluator(chanA.node, chanA.path, new CompositeOutput(oA, oB, chanA.path, this) ))
        
      }
      
      // list all remaining channels in current and mix them using DefaultStateProvider
      for (let i = 0; i < channelsBCopy.length; i++) {
        const chanB = channelsBCopy[i];
        oA = new StaticOutput( this.defaultStateProvider.getState( chanB.node, chanB.path ) )
        oB = new ChannelOutput( chanB, this.currentAnimation )
        this.outputs.push( new ChannelEvaluator(chanB.node, chanB.path, new CompositeOutput(oA, oB, chanB.path, this) ))
      }


    } else {

      const channels = this.currentAnimation.anim.channels
  
      for (const chan of channels) {
        const o = new ChannelOutput( chan, this.currentAnimation )
        this.outputs.push( new ChannelEvaluator(chan.node, chan.path, o ))
      }
    }

  }


}




export default class AnimationData {

  public time: number = 0
  private _lastEval: number = 0


  constructor( readonly anim: Animation, private seq: CharacterSequence ){
    this._lastEval = now()
  }
  
  
  detachSequence(){
    this.seq = null
  }
  
  
  updateTime(){
    const n = now();

    if( this.seq && this.seq.isPlaying ){
      this.time = this.seq.currentTime
    } else {
      const deltaTime = n-this._lastEval
      this.time += deltaTime/1000
    }
    
    this._lastEval = n
    
    
    // this.anim.evaluate( this.time )

  }



}