

























import Paths from "@/core/Paths";
import TrackingEbn from "@/core/TrackingEbn";
import ebn_state from "@/store/modules/ebn_state";
import Vue from "vue";
import Component from "vue-class-component";
import EBNButton from "../EBNButton/EBNButton.vue";

@Component({
  components: {
    EBNButton,
  },
})
export default class EBNSharePanel extends Vue {
  videoPlaying = false;

  get coverUrl(): string {
    const char = ebn_state.currentCharacter;
    return Paths.resolve(`assets/images/video_cover_${char}.jpg`);
  }

  get videoUrl(): string {
    const char = ebn_state.currentCharacter;
    return Paths.resolve(`assets/medias/share_video_${char}.mp4`);
  }

  get nativeShareAvailable(): boolean {
    if (process.env.VUE_APP_DEBUG === "true") return true;
    return navigator.share !== undefined;
  }

  get title() {
    return this.$t(`${ebn_state.currentCharacter}.share.title`).toString();
  }

  getVideo(): HTMLVideoElement {
    return this.$refs.video as HTMLVideoElement;
  }

  clickVideo() {
    if (this.videoPlaying) this.getVideo().pause();
    else {
      TrackingEbn.playVideo(ebn_state.currentCharacter, "play video");
      this.getVideo().play();
    }
  }

  mounted() {
    this.getVideo().addEventListener("play", this.onVideoStart);
    this.getVideo().addEventListener("pause", this.onVideoStop);
  }

  onVideoStart() {
    this.videoPlaying = true;
  }

  onVideoStop() {
    this.videoPlaying = false;
  }

  onShare() {
    // const title = this.$t(
    //   `${ebn_state.currentCharacter}.share.title`
    // ).toString();
    // const text = this.$t(`${ebn_state.currentCharacter}.share.text`).toString();
    // const url = this.$t(`${ebn_state.currentCharacter}.share.url`).toString();

    // if (navigator.share) navigator.share({ url, title, text });
    TrackingEbn.endLink("DOWNLOAD & SHARE VIDEO");
    const anchor = document.createElement("a");
    anchor.href = this.videoUrl;
    anchor.download = this.videoUrl;
    document.body.appendChild(anchor);
    anchor.click();
  }
}
