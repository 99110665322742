












/////////////
/////////////////////////////////////////////////
//////////

import Vue from "vue";
import Component from "vue-class-component";

import RotateHint from "@/components/RotateHint/RotateHint.vue";
import EBNMainPage from "@/components/EBNMainPage/EBNMainPage.vue";

import EBNDesktopPage from "@/components/EBNDesktopPage/EBNDesktopPage.vue";
import TrackingEbn from "@/core/TrackingEbn";

@Component({
  components: {
    EBNMainPage,
    RotateHint,
    EBNDesktopPage,
  },
})
export default class EvEntry extends Vue {
  get debugbg() {
    return process.env.VUE_APP_SKIP_8THWALL === "true";
  }

  get fpsComponent() {
/////////////////
/////////////////
//////////////
  }

  get isMobile() {
/////////////////
////////////////////////////////////////////////////////
//////////////////
/////
//////////////

    const isDesktopMQ = window.matchMedia("only screen and (pointer: coarse)");
    //console.log("sDesktopMQ.matches", isDesktopMQ.matches);
    if (!isDesktopMQ.matches) document.documentElement.classList.add("desktop");
    return isDesktopMQ.matches;
  }

  mounted() {
    TrackingEbn.pageView();
  }
}
