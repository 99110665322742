








































import Vue from "vue";
import Component from "vue-class-component";
import Paths from "@/core/Paths";
import LigtningSvg from "!vue-svg-loader!@/assets/icons/lightning.svg";

import gsap, { Expo, Quart } from "gsap";
import ebn_state from "@/store/modules/ebn_state";
import Delay from "@/core/Delay";
import CustomEase from "./CustomEase";
import { LocaleMessage } from "vue-i18n";
import ebn_market from '../../store/modules/ebn_market';

@Component({
  components: {
    LigtningSvg,
  },
})
export default class EBNIntro extends Vue {
  elX: number = 0;
  sX: number = 0;
  iHeight: number = 0;
  iWidth: number = 0;
  moveX: number = 0;
  introOver: boolean = false;

  logoVideo: HTMLVideoElement = null;

  easeBack = CustomEase.create(
    "custom",
    "M0,0 C0.128,0.572 0.229,1.081 0.49,1.03 0.642,1 0.838,1 1,1 "
  );

  coversEase = [
    Quart.easeInOut,
    Quart.easeInOut,
    this.easeBack,
    this.easeBack,
    Quart.easeInOut,
    Quart.easeInOut,
    Quart.easeInOut,
  ];

  coversEaseOut = Quart.easeInOut;
  coversParallax = [1.5, 3.8, 3.8, 3, 4.6, 4, 4.9];
  coversRotation = [false, true, false, false, true, true, true];

  get introDone() {
    return ebn_state.introDone;
  }

  get bottom(): LocaleMessage {
    return this.$t("desktop.bottom");
  }

  get carURL(): string {
    if(ebn_market.currentMarketEBN == "mx") {
      return Paths.resolve(`assets/images/mx/intro-cover-car_mx.png`);
    }

    return Paths.resolve(`assets/images/intro-cover-car.png`);
  }

  get coverTitleFP() {
    return (this.$t("intro.title") as string)
      .split("<br />")[0]
      .split("")
      .map((c) =>
        c == " " 
          ? `<div style="display: inline;">${c}</div>`
          : `<div>${c}</div>`
      )
      .join("");
  }

  get coverTitleEP() {
    return (this.$t("intro.title") as string)
      .split("<br />")[1]
      .split("")
      .map((c) =>
        c == " " 
          ? `<div style="display: inline;">${c}</div>`
          : `<div>${c}</div>`
      )
      .join("");
  }

  async mounted() {
    if (process.env.VUE_APP_SKIP_PRELOAD === "true") {
      ebn_state.setIntroDone(true);
      ebn_state.setVideoDone(true);
      this.introOver = true;
      return;
    }

    this.$brightcovePlayerLoader({
      refNode: this.$refs.logoVideo,
      refNodeInsert: "append",
      accountId: "6053147451001",
      playerId: "default",
      videoId: "6282344979001",
      autoplay: true,
      playsinline: true,
      poster: '',
      muted: true,
    }).then(() => {
      this.logoVideo = (this.$refs.logoVideo as HTMLElement).querySelector(
        "video"
      );
      const player = (this.logoVideo.parentNode as any).player;
      player.ready(async () => {
        this.logoVideo.playsInline = true;
        this.logoVideo.muted = true;
        this.logoVideo.addEventListener("canplaythrough", this.logoCanPlay);
        player.controlBar.progressControl.disable();
        this.logoVideo.load();
        await Delay(500);
        (this.$refs.logoVideo as HTMLElement).classList.add("show")
        this.logoVideo.pause();
        this.logoVideo.play();
        await Delay(500);
        (this.$refs.waitingLogo as HTMLElement).classList.add("hide");
      });
    });
  }

  beforeDestroy() {
    if (this.logoVideo)
      this.logoVideo.removeEventListener("canplaythrough", this.logoCanPlay);

    this.$brightcovePlayerLoader.reset();
  }

  async logoCanPlay() {
    this.logoVideo.play();
    await Delay(3500);
    this.launchAnimation();
    await Delay(1000);
    ebn_state.setVideoDone(true);
  }

  async launchAnimation() {
    this.animateMask(true);
    this.animateCoverIn(0.1);
    await Delay(3000);
    ebn_state.setIntroDone(true);
    this.animateCoverOut(0.1);
    this.animateMask(false);
    await Delay(2000);
    this.introOver = true;
  }

  animateMask(inOut: boolean) {
    this.iHeight = window.innerHeight;
    this.iWidth = window.innerWidth;
    gsap.to(this, {
      duration: 1.75,
      elX: inOut ? 1 : 2,
      sX: inOut ? 1 : 0,
      ease: Expo.easeInOut,
    });
  }

  animateCoverIn(delay: number = 0) {
    this.animateCover(true, delay);
    this.animateTitle();
  }

  animateCoverOut(delay: number = 0) {
    this.animateCover(false, delay);
    gsap.to(this.$refs.bottom, { duration: 1, opacity: 0 });
  }

  animateCover(inOut: boolean, delay: number = 0) {
    const els: Array<HTMLElement> = Array.from(
      this.$el.getElementsByClassName("parallax")
    ) as Array<HTMLElement>;
    els.forEach((c: HTMLElement, i) => {
      const screenOut = this.coversParallax[i] * window.innerWidth;
      gsap.fromTo(
        c,
        {
          x: inOut ? screenOut : 0,
          rotation: this.coversRotation[i] ? (inOut ? 40 : 0) : 0,
        },
        {
          x: inOut ? 0 : -screenOut,
          rotation: inOut ? 0 : this.coversRotation[i] ? 40 : 0,
          duration: 2,
          ease: inOut ? this.coversEase[i] : this.coversEaseOut,
          delay,
        }
      );
    });
  }

  animateTitle() {
    gsap.fromTo(
      ".ebn-intro h1 .split div",
      { x: 150, rotation: 30 },
      {
        x: 0,
        rotation: 0,
        duration: 0.3,
        delay: 0.65,
        stagger: 0.035,
        ease: Quart.easeInOut,
      }
    );
  }
}
