






















import LottieAnimation from "../LottieAnimation/LottieAnimation.vue";
import Paths from "@/core/Paths";
import ebn_state from "@/store/modules/ebn_state";
import ebnCharacterControllers from "@/webgl/entities/ebn/Characters";
import Vue from "vue";
import Component from "vue-class-component";
import TrackingEbn from "@/core/TrackingEbn";
import { LocaleMessage } from "vue-i18n";
import Delay from "@/core/Delay";
import ebn_market from '@/store/modules/ebn_market';

@Component({
  components: {
    LottieAnimation,
  },
})
export default class EBNHowToScreen extends Vue {
  get currentChar() {
    return ebn_state.currentCharacter;
  }

  get lottie() {
    if(ebn_market.currentMarketEBN == "mx" && ebn_state.currentCharacter == "zoe") {
      return `zoe_mx`;
    }

    return this.currentChar
  }

  get title(): LocaleMessage {
    return this.$t(this.currentChar + ".tuto_title");
  }

  get svgPath() {
    return Paths.resolve(
      `assets/images/tuto-${ebn_state.currentCharacter}.svg`
    );
  }

  get constance() {
    return ebn_state.currentCharacter === "constance";
  }

  async mounted() {
    await Delay(5000);
    this.onSkip();
  }

  onClick() {
    ebnCharacterControllers.skipTuto();
    TrackingEbn.skipTuto(ebn_state.currentCharacter);
  }

  onSkip() {
    ebnCharacterControllers.skipTuto();
  }
}
