
















import ebn_state from "@/store/modules/ebn_state";
import ebnCharacterControllers from "@/webgl/entities/ebn/Characters";
import Vue from "vue";
import Component from "vue-class-component";
import EBNButton from "../EBNButton/EBNButton.vue";

@Component({
  components: {
    EBNButton,
  },
})
export default class RobertInteractionView extends Vue {
  get closed() {
    return ebn_state.riDragged;
  }

  mounted() {
    const el = this.$el as HTMLElement;

    el.addEventListener("touchstart", this.onTouchStart);
    window.addEventListener("touchend", this.onTouchEnd);
    window.addEventListener("touchcancel", this.onTouchEnd);

/////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
//////////////
  }

  destroyed() {
    ebn_state.ri_stopDrag();
    window.removeEventListener("touchend", this.onTouchEnd);

/////////////////
//////////////////////////////////////////////////////////
//////////////
  }

  onTouchStart = (e: TouchEvent) => {
    const tid = e.touches[0].identifier;
    if (ebn_state.riTouchID === -1) {
      ebn_state.ri_startDrag(tid);
    }
  };

  onTouchEnd = (e: TouchEvent) => {
    const ctid = ebn_state.riTouchID;
    if (!Array.from(e.touches).find((t) => t.identifier === ctid)) {
      ebn_state.ri_stopDrag();
    }
  };

///////////////

///////////////////////
////////////////////////////////////
//////////////////////////////////
/////
////

/////////////////////
////////////////////////////
////

////////////

  onSuccess() {
    ebnCharacterControllers.markAsSuccess();
  }

  onFailure() {
    ebnCharacterControllers.markAsFailure();
  }
}
