

















import TrackingEbn from "@/core/TrackingEbn";
import { getCharacterConfig } from "@/store/datas/ebn_character_configs";
import ebn_state from "@/store/modules/ebn_state";
import Vue from "vue";
import Component from "vue-class-component";
import EBNToggleBtn from "../EBNToggleBtn/EBNToggleBtn.vue";
import EBNTriggerCounter from "../EBNTriggerCounter/EBNTriggerCounter.vue";
import UIIconBtn from "../UIIconBtn/UIIconBtn.vue";

@Component({
  components: {
    UIIconBtn,
    EBNToggleBtn,
    EBNTriggerCounter,
  },
})
export default class EBNFooter extends Vue {
  get subtitlesEnabled() {
    return ebn_state.subtitlesEnabled;
  }
  set subtitlesEnabled(value: boolean) {
    TrackingEbn.toggleSubtitles(value);
    ebn_state.setSubtitlesEnabled(value);
  }

  get char() {
    return ebn_state.currentCharacter;
  }

  get uspTotal() {
    return getCharacterConfig(ebn_state.currentCharacter).numUsps;
  }

  get uspCount() {
    return ebn_state.completedUspsCount;
  }

  get totalUsps() {
    return ebn_state.totalUsps;
  }

  shareTag() {
    TrackingEbn.share();
    const title = this.$t(`share.title`).toString();
    const text = this.$t(`share.text`).toString();
    const url = this.$t(`share.url`).toString();

    if (navigator.share) {
      navigator.share({ url, title, text });
    }
  }
}
