










































































































































import Paths from "@/core/Paths";
import { EbnCharacter } from "@/store/models/EbnCharacters";
import ebn_state from "@/store/modules/ebn_state";
import viewport from "@/store/modules/viewport";
import { clamp01, remapProgress, smoothstep } from "@/webgl/math";
import { easeOutCubic } from "@/webgl/math/ease";
import gsap from "gsap";
import { Quart } from "gsap/all";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CircleRenderer from "./CircleRenderer";
import ebn_market from '../../store/modules/ebn_market';

const BACKGROUNDS = ["#78CD9E", "#F8B071", "#50AAE0", "#F5D37F"];

@Component
export default class CharacterPanel extends Vue {
  @Prop({ type: String, required: true }) characterId!: EbnCharacter;
  @Prop({ type: Number, required: true }) id!: number;
  @Prop({ type: Number, required: true }) position!: number;
  @Prop({ type: Number, default: 0 }) offsetX!: number;

  coverIntroX: number = 0;

  // public position = 0

  get coverIntroXTransform() {
    const p = Math.max(-1, Math.min(1, this.position));
    const intro =
      this.id === 0 || ebn_state.isRevisit !== -1 ? this.coverIntroX * 1920 : 0;
    return `${this.vpw / 2 -
      this.imageRatio / 2 +
      this.offsetX * this.imageRatio +
      intro +
      p * (1920 / 4)}px`;
  }

  get coverIntroOpacity() {
    const p = smoothstep(
      1,
      0.8,
      Math.max(0, Math.min(1, Math.abs(this.position)))
    );
    return p;
  }

  coverIntroVisibility() {
    return Math.abs(this.position) < 1 ? "visible" : "hidden";
  }

  get bgColor(): string {
    return BACKGROUNDS[this.id];
  }

  get coverUrl(): string {
    if(ebn_market.currentMarketEBN == "mx") {
      return Paths.resolve(`assets/images/mx/carousel-${this.characterId}_mx.jpg`);
    }

    return Paths.resolve(`assets/images/carousel-${this.characterId}.jpg`);
  }

  get visible() {
    return Math.abs(this.position) < 1.1;
  }

  get current() {
    if (this.position > -0.5 && this.position <= 0.5)
      ebn_state.setCarouselChar(this.characterId);
    return this.position > -0.5 && this.position <= 0.5;
  }

  get useMask() {
    return !this.current;
  }

  get vpw() {
    return viewport.windowWidth;
  }

  get vph() {
    return viewport.windowHeight;
  }

  get imageRatio() {
    return (this.vph / 1080) * 1920;
  }

  get patternScale() {
    let p = remapProgress(this.position, 1, 0.3);
    // let p = 2 - 2*Math.abs( this.position )
    p = clamp01(p);
    p = easeOutCubic(p);
    return 0.5 + p * 0.6;
  }

  get patternDx() {
    if (this.position < 0) return 0;
    return (this.vpw * (1.1 - this.patternScale)) / this.patternScale;
  }

  get patternDy() {
    return (this.vph * (1 - this.patternScale)) / this.patternScale;
  }

  get maskPath() {
    let progress;
    if (this.position < 0) progress = this.position * 2 + 2;
    else progress = this.position * -2 + 2;

    return CircleRenderer.renderProgress(
      progress,
      this.position < 0,
      this.vpw,
      this.vph,
      false,
      Math.max(0.001, 1 - this.coverIntroX)
    );
  }

  get clipPath() {
    let progress;
    if (this.position < 0) progress = this.position * 2 + 2;
    else progress = this.position * -2 + 2;

    return CircleRenderer.renderProgress(
      progress,
      this.position < 0,
      this.vpw,
      this.vph,
      true
    );
  }

  mounted() {
    this.slideImage();
  }

  slideImage() {
    this.coverIntroX = 1;
    gsap.to(this, {
      duration: 2,
      coverIntroX: 0,
      delay: 0.5,
      ease: Quart.easeOut,
    });
  }
}
