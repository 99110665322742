import Paths from "@/core/Paths";
import TrackingEbn from "@/core/TrackingEbn";
import ebn_state from "@/store/modules/ebn_state";
import { Passes } from "@/webgl/glsl/Passes";
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { mat4Xaxis, mat4Yaxis, mat4Zaxis } from "@/webgl/math/mat4-axis";
import Scene from "@/webgl/Scene";
import { vec3, vec4 } from "gl-matrix";
import Node from "nanogl-node";
import GltfModule from "../GltfModule";
import CharacterController from "./CharacterController";
import { ICharacterInteraction } from "./Characterinteraction";


const V3A = vec3.create()
const V3B = vec3.create()
const V3C = vec3.create()
const V4A = vec4.create()


interface Trajectory {
  getPosAt( out:vec3, time:number ):boolean
}


class PhysicalTrajectory implements Trajectory {

  pos = vec3.create()
  vel = vec3.create()
  currentTime = 0
  initialTime = 0

  init( pos: vec3, velocity: vec3, time:number ){
    this.vel.set( velocity )
    this.pos.set( pos )
    this.initialTime = time
    this.currentTime = time
  }

  getPosAt( out:vec3, time:number ):boolean {

    const gravity = -.3
    const step = .1
    let fdt = time - this.currentTime
    while( fdt > 0 ){
      let dt
      if( fdt > step ){
        dt = step
        fdt -= step
      } else {
        dt = fdt
        fdt = 0
      }

      this.vel[1] += dt * gravity

      vec3.scaleAndAdd( this.pos, this.pos, this.vel, dt )

      if( this.pos[1] < 0 ){
        this.pos[1] = 0.001
        this.vel[1] *= -1
        vec3.scale( this.vel, this.vel, .5)
      }

    }
    
    out.set( this.pos )

    return ( time-this.initialTime > 3 )

  }

}


class HitTrajectory implements Trajectory {

  duration = .5

  pos = vec3.create()
  tgt = vec3.create()
  currentTime = 0
  initialTime = 0
  dist: number;

  constructor( start: vec3, target: vec3, time:number ){
    this.tgt.set( target )
    this.pos.set( start )
    this.dist = vec3.distance( start, target )
    this.initialTime = time
  }

  getPosAt( out:vec3, time:number ):boolean {

    const progress = (time-this.initialTime)/this.duration
    if (progress>1) return true

    vec3.lerp( out, this.pos, this.tgt, progress)

    const x = progress*2-1
    out[1] += (1-(x*x))*this.dist*.3

    return false
  }  

}



export class Berry {

  node : Node
  trajectory: Trajectory|null

  constructor(){
    this.node = new Node()
  }

}




const MAX_HISTORY = 3

type TouchHistory = {
  x:number
  y:number
  time:number
}


export class Throwing implements Trajectory {

  history : TouchHistory[] = []

  debugPos = [0, 0]
  debugVel = [0, 0]
  berry: Berry;

  constructor( private interaction:RobertInteraction ){

  }


  startDrag(){
    this.berry = this.interaction.createBerry()
    this.berry.trajectory = this
    this.history.length = 0
    window.addEventListener('touchmove', this.onTouchMove )
    window.addEventListener('mousemove', this.onMouseMove )
  }
  
  stopDrag(){
    window.removeEventListener('touchmove', this.onTouchMove )
    window.removeEventListener('mousemove', this.onMouseMove )

    TrackingEbn.throwBerry()

    if( this.history.length === 0 ) {
      if( this.berry ){
        this.interaction.deleteBerry(this.berry)
        this.berry = null
      }
      return;
    }

    


    const last = this.history[this.history.length-1]
    this.debugPos = [last.x, last.y]
    this.debugVel = this.getMeanVelocity()

    


    const target = this.interaction.getTargetPosition()
    V4A.set( target )
    V4A[3] = 1
    vec4.transformMat4( V4A, V4A, this.interaction.scene.camera._viewProj )
    
    const sx = V4A[0] / V4A[3]
    const sy = V4A[1] / V4A[3]
    

    let traj : Trajectory

    //console.log(V4A, sx, sy);
    
    // birds target is in viewport
    if( V4A[3] > 0 && sx > -1 && sx < 1 && sy > -1 && sy < 1 ) {
      traj = new HitTrajectory( this.berry.node._wposition as vec3, target, performance.now()/1000 )
      this.interaction.controler.markAsSuccess()
    } else {
      traj = this.createFreeTrajectory()
      this.interaction.controler.markAsFailure()
    }



    this.berry.trajectory = traj
    this.berry = null



    this.history.length = 0
  }


  createFreeTrajectory() {
    const traj = new PhysicalTrajectory()
    mat4Zaxis( V3A, this.interaction.scene.camera._wmatrix)
    vec3.scale( V3A, V3A, -1)

    mat4Xaxis( V3B, this.interaction.scene.camera._wmatrix)
    mat4Yaxis( V3C, this.interaction.scene.camera._wmatrix)

    vec3.normalize( V3A, V3A)
    
    const v2 =  this.getMeanVelocity()
    const vx =  v2[0]
    const vy =  v2[1]
    const vel = Math.sqrt( vx*vx + vy*vy)
    
    
    vec3.normalize( V3A, V3A)
    vec3.scale( V3A, V3A, vel * .02 )
    vec3.scaleAndAdd( V3A, V3A, V3B, vx * .02)
    vec3.scaleAndAdd( V3A, V3A, V3C, vy * .02)
    
    
    traj.init( this.berry.node._wposition as vec3, V3A, performance.now()/1000 )
    return traj
  }
 

  onTouchMove = (e:TouchEvent) => {
    const w = this.interaction.scene.glview.canvasWidth
    const h = this.interaction.scene.glview.canvasHeight
    const id = ebn_state.riTouchID
    const t = Array.from(e.touches).find( t=>t.identifier===id)
    if( t ){
      this.handleMove(
        t.clientX/w * 2 - 1,
        -(t.clientY/h * 2 - 1)
      )
    }
  }


  onMouseMove = (e:MouseEvent) => {
    const w = this.interaction.scene.glview.canvasWidth
    const h = this.interaction.scene.glview.canvasHeight
    this.handleMove(
      e.clientX/w * 2 - 1,
      -(e.clientY/h * 2 - 1)
    )
  }

  handleMove( x:number, y:number ){
    const time = performance.now()
    this.history.push( {x, y, time})

    this.debugPos = [x, y]
    if( this.history.length > MAX_HISTORY ) {
      this.history.shift()
    }
  }

  

  getPosAt(out: vec3, time: number): boolean {

    const scene  = this.interaction.scene
    const camera  = scene.camera

    camera.updateWorldMatrix()
    camera.updateViewProjectionMatrix( scene.glview.width, scene.glview.height )

    const pos = this.debugPos
    V3A[0] = pos[0]
    V3A[1] = pos[1]
    V3A[2] = 5

    
    camera.unproject( V3B, V3A )
    vec3.subtract( V3A, V3B, camera._wposition as vec3 )
    vec3.normalize( V3A, V3A )
    vec3.scaleAndAdd( V3B, camera._wposition as vec3, V3A, -.5 )

    vec3.transformMat4( V3B, V3B, scene.invRootMatrix)
  
    out.set( V3B )

    return false
  }

  getMeanVelocity(){
    
    const now = performance.now()
    const maxAge = 200
    let c = 0
    let vx = 0
    let vy = 0

    for (let i = this.history.length-1; i > 0; i--) {
      const t1 = this.history[i];
      const t0 = this.history[i-1];

      if( now - t0.time > maxAge ) break

      const dt = (t1.time - t0.time)/1000
      const dx = (t1.x - t0.x)/dt
      const dy = (t1.y - t0.y)/dt

      vx += dx
      vy += dy

      c++

    }

    if( c === 0 ) return [0, 0] 

    vx /= c
    vy /= c

    return [vx, vy]

  }

  update( ){

  }

}


export default class RobertInteraction implements ICharacterInteraction {

  public renderBefore:boolean = false

  berries: Berry[] = []

  berryGltf : GltfModule
  throwing: Throwing

  private _unwatch: Function;
  scene: Scene;
  ready: boolean;
  controler: CharacterController;

  getTargetPosition() : vec3{
    const n = this.controler.model.gltfModule.gltf.root//getElementByName( GltfTypes.NODE, 'target' )
    n.updateWorldMatrix()
    return n._wposition as vec3
  }

  init(  ctrl:CharacterController ){
    this.controler = ctrl
    this.scene = ctrl.scene
    this.berryGltf = new GltfModule( this.scene, Paths.resolve( 'assets/webgl/gltf/berry.glb' ) )

    //hide berry on init
    //const b = this.createBerry()
    //b.node.x = 0//i*.1

    // for (let i = 0; i < 10; i++) {
    // }

    this.throwing = new Throwing(this)

  }

  enter(): void {
    this._unwatch?.()
    this._unwatch = ebn_state.$watch( s=>s.riDragged, this.onDraggedChange )
  }
  
  leave(): void {
    this._unwatch?.()
    this._unwatch = null
  }
  
  dispose(): void {
    while( this.berries.length > 0 ){
      this.deleteBerry( this.berries[0] )
    }
    this._unwatch?.()
  }
  
  async load(): Promise<void> {
    await this.berryGltf.load()
    this.ready = true
    // this.scene.root.add(this.berryGltf.gltf.root)
  }

  createBerry(): Berry {
    const b = new Berry()
    b.node.setScale( 0.05 )
    this.scene.root.add( b.node )
    this.berries.push( b )
    return b
  }

  deleteBerry(b:Berry) {
    const i = this.berries.indexOf(b)
    if( i > -1 ){
      this.scene.root.remove( b.node )
      this.berries.splice( i, 1 )
    }
    
  }


  onDraggedChange = (val:boolean) => {
    val ? this.startDrag() : this.stopDrag()
  }

  startDrag() {
    this.throwing.startDrag()
  }
  
  stopDrag() {
    this.throwing.stopDrag()
  }


  
  preRender(): void {
    0
  }
  
  rttRender(): void {
    this.berryGltf.rttRender()


  }

  render( ): void {


    const camera = this.scene.camera



    const t = performance.now() / 1000

    for (let i = this.berries.length-1; i > - 1; i--) {
      const b = this.berries[i];
      if( b.trajectory ){
        const remove = b.trajectory.getPosAt( b.node.position, t )
        if( remove ) this.deleteBerry( b )
        b.node.invalidate()
        b.node.updateWorldMatrix()
      }
    }







    // this.berryGltf.render()

    const glstate = this.scene.glstate

    const berry   = this.berryGltf.gltf.getElementByName( GltfTypes.NODE, "berry")

    const renderer = berry.renderable as MeshRenderer
    const primitive = renderer.mesh.primitives[0];
    const mat = renderer.materials[0]
    const passInstance = mat.getPass( Passes.DEFAULT );

    const prg = passInstance.getProgram()


    glstate.push( passInstance.pass.glconfig );
    mat.glconfig  && glstate.push(mat.glconfig);
    renderer.glconfig && glstate.push(renderer.glconfig);
    
    glstate.apply()
    
    for (const instance of this.berries) {
      passInstance.prepare( instance.node, camera )
      renderer.drawCall(camera, prg, primitive);
    }


    glstate.pop();
    mat.glconfig  && glstate.pop();
    renderer.glconfig && glstate.pop();

  }

}