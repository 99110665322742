































import Paths from "@/core/Paths";
import TrackingEbn from "@/core/TrackingEbn";
import { EbnCharacter, EbnCharacters } from "@/store/models/EbnCharacters";
import ebn_state, { EBNMainState } from "@/store/modules/ebn_state";
import car_features from "@/store/datas/car_features.json";
import car_features_mx from "@/store/datas/car_features_mx.json";
import Vue from "vue";
import Component from "vue-class-component";
import ebnCharacterControllers from "@/webgl/entities/ebn/Characters";
import Delay from "@/core/Delay";
import ebn_market from "@/store/modules/ebn_market";

@Component
export default class EBNCharacterSelector extends Vue {
  get opened() {
    return ebn_state.charSelectorOpened;
  }

  get characterIds() {
    return EbnCharacters;
  }

  get currentChar() {
    return ebn_state.currentCharacter;
  }

  get carFeatures() {
    if(ebn_market.currentMarketEBN == "mx") {
      return car_features_mx
    }

    return car_features
  }

  get show() {
    return (
      !ebn_state.menuPanelOpened &&
      ebn_state.mainState !== EBNMainState.CAROUSEL
    );
  }

  getPicto(char: EbnCharacter): string {
    return Paths.resolve(`assets/images/picto_char_${char}.jpg`);
  }

  async selectChar(char: EbnCharacter) {
    TrackingEbn.clickCharBar(char);
    ebn_state.setSwitchCharacters(true);
    ebnCharacterControllers.dispose();

    await Delay(400);
    ebn_state.setSwitchCharacters(false);
    ebn_state.setCurrentCharacter(char);
    ebn_state.closeCharSelector();

    await Delay(600);
    ebn_state.closeCarFeatures();
    const character = ebn_state.currentCharacter;
    const feature = this.carFeatures.filter(v => v.character == character)[0];
    ebn_state.setCurrentFeature(feature);
  }

  mounted() {
    window.addEventListener("click", this.onWindowClick);
  }

  onWindowClick() {
    if (this.opened) {
      ebn_state.closeCharSelector();
    }
  }

  onClick() {
    TrackingEbn.globalNav("characters");
    if (this.opened) ebn_state.closeCharSelector();
    else ebn_state.openCharSelector();
  }
}
