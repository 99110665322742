







import Vue from "vue";
import Component from "vue-class-component";
import GLApp from "@webgl/main";
import EBNModule from "@/webgl/EBNModule";
import ebnCharacterControllers from "@/webgl/entities/ebn/Characters";
import ebn_state from "@/store/modules/ebn_state";
import { Watch } from "vue-property-decorator";
// import EVHotspots from '../EVHotspots/EVHotspots.vue';

@Component({
  components: {},
})
export default class EBNArView extends Vue {
  public loaded = false;
  public loadProgress = 0;

  private viewer: GLApp;

  mounted() {
    this.init();
  }

  init() {
    const canvas: HTMLCanvasElement = this.$refs.canvas as HTMLCanvasElement;
    this.viewer = new GLApp(canvas as HTMLCanvasElement, new EBNModule());

    if (process.env.VUE_APP_SKIP_8THWALL === "true") {
      canvas.style.display = "none";
    }

    this.load();

    ebnCharacterControllers.start();
  }

  destroyed() {
    ebnCharacterControllers.dispose();
    this.viewer.stop();
    this.viewer.scene.dispose();
    this.viewer = null;
    console.log("stoping ar");
  }

  async load() {
    await this.viewer.load();
    this.loaded = true;
  }

  closePanel() {
    ebn_state.closeDisclaimer();
    ebn_state.closeCarFeatures();
  }
}
