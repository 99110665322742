
export enum EbnAnimationMultiSequence {

  WAITING    = 'waiting'      ,
  MOTIVATION = 'motivational' ,
  SUCCESS    = 'success'      ,
  FAILURE    = 'failure'      ,
  TRIGGER    = 'trigger'      ,
  SPEECH     = 'speech'

}

export enum EbnAnimationIdentifier {
  INTRO      = 'intro'     ,
  IDLE       = 'idle'      ,
  MOTIVATION = 'motivational',
  OUTRO      = 'outro'     ,
  
  // optional
  
  
  FAILURE_1  = 'failure_1' ,
  FAILURE_2  = 'failure_2' ,
  FAILURE_3  = 'failure_3' ,
  FAILURE_4  = 'failure_4' ,
  
  
  SPEECH_1   = 'speech_1'  ,
  SPEECH_2   = 'speech_2'  ,
  SPEECH_3   = 'speech_3'  ,
  SPEECH_4   = 'speech_4'  ,
  
  
  TRIGGER_1  = 'trigger_1' ,
  TRIGGER_2  = 'trigger_2' ,
  TRIGGER_3  = 'trigger_3' ,
  TRIGGER_4  = 'trigger_4' ,
  
  
  SUCCESS_1  = 'success_1' ,
  SUCCESS_2  = 'success_2' ,
  SUCCESS_3  = 'success_3' ,
  SUCCESS_4  = 'success_4' ,
  

  WAITING_1  = 'waiting_1' ,
  WAITING_2  = 'waiting_2' ,
  WAITING_3  = 'waiting_3' ,
  WAITING_4  = 'waiting_4' ,

}



export type EbnAnimationSequence = EbnAnimationMultiSequence | EbnAnimationIdentifier



export function EbnAnimationSequence_getAnimationAt( s: EbnAnimationMultiSequence, n: number): EbnAnimationIdentifier {
  return (s + '_' + (n+1)) as EbnAnimationIdentifier
}



export function isMultiAnim( s:EbnAnimationSequence ) : s is EbnAnimationMultiSequence {
  switch (s) {
    case EbnAnimationMultiSequence.WAITING:
    case EbnAnimationMultiSequence.SUCCESS:
    case EbnAnimationMultiSequence.FAILURE:
    case EbnAnimationMultiSequence.TRIGGER:
    case EbnAnimationMultiSequence.SPEECH :
      return true
  }
  return false
}

export function isAnimationLoop( s:EbnAnimationIdentifier ) : boolean {
  switch (s) {
    case EbnAnimationIdentifier.WAITING_1:
    case EbnAnimationIdentifier.WAITING_2:
    case EbnAnimationIdentifier.WAITING_3:
    case EbnAnimationIdentifier.WAITING_4:
    case EbnAnimationIdentifier.IDLE:
    case EbnAnimationIdentifier.MOTIVATION:
      return true
  }
  return false
}