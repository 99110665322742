
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"18","height":"29","viewBox":"0 0 18 29","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M17.155 12.991a1.077 1.077 0 00-.385-.41 1.015 1.015 0 00-.53-.15h-5.253V1.432a1.136 1.136 0 00-.201-.689c-.14-.2-.338-.344-.566-.41a1.007 1.007 0 00-.665.036 1.064 1.064 0 00-.512.448L.638 15.114a1.14 1.14 0 000 1.1c.082.176.209.327.366.435.157.108.339.17.527.18h5.253v10.997c-.008.247.063.49.202.689.139.2.338.344.565.41.094.017.19.017.284 0 .18 0 .356-.05.512-.142.157-.092.288-.225.381-.385l8.406-14.296a1.138 1.138 0 00.02-1.11z","fill":"#fff"}})])
          )
        }
      }
    