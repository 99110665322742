import { render, staticRenderFns } from "./Joel.vue?vue&type=template&id=cdc70992&scoped=true&"
import script from "./Joel.vue?vue&type=script&lang=ts&"
export * from "./Joel.vue?vue&type=script&lang=ts&"
import style0 from "./Joel.vue?vue&type=style&index=0&id=cdc70992&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdc70992",
  null
  
)

export default component.exports